import React from 'react'
import IntervalTimer from 'react-interval-timer'
import { videoLastViewedSet } from '../../services/userVideoViewings'
import { IS_IOS, IS_IPHONE } from '../../utils/iosDetection'
import './audioPlayer.css'
import './mobileUi'

const videojs = window.videojs
const IS_MOBILE = videojs.browser.IS_ANDROID || IS_IOS

export default class AudioPlayer extends React.Component {
  constructor() {
    super()
    this.state = { media: {audioUrl: ''}, time: {start: 0, timestamp: 0} }
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    // Create an instance of video.js
    this.player = videojs(this.videoNode, {
      // hide additional controls on mobile
      controlBar: {
        pictureInPictureToggle: !IS_MOBILE,
        volumeControl: false,
        //hide fullscreen button
        fullscreenToggle: false
      },
      controls: true,
      // use native controls for iPhone
      // nativeControlsForTouch: IS_IPHONE,
      autoplay: true,
      preload: 'auto'
    })
    this.player.on('pause', () => { this.saveUserCurrentTime() })


    // Activate plugins
    this.player.landscapeFullscreen({
      fullscreen: {
        enterOnRotate: true,
        alwaysInLandscapeMode: false,
        iOS: IS_IPHONE
      }
    })
    this.player.mobileUi({
      touchControls: {
        seekSeconds: 10,
        tapTimeout: 300,
        disableOnEnd: false
      }
    })
    this.player.extraButtons({
      quickBackward: { seconds: 10 },
      quickForward: { seconds: 10 },
      // TODO: not working properly
      qualitySelect: [
        { bandwidth: 524288, name: "SD" },
        { bandwidth: 1048576, name: "HD" },
        { bandwidth: 2097152, name: "QHD" },
        { bandwidth: 4194304, name: "UHD" }
      ], // only works when source is hls(m3u8)
      playbackRateSelect: IS_MOBILE ? false : [1.0, 1.2, 1.4, 1.6, 2.4]
    })

    document.addEventListener("keydown", this.handleKeyDown);
    
  }

  componentDidUpdate() {
    if (this.props.media && this.props.media.audioUrl &&
      this.state.media.audioUrl !== this.props.media.audioUrl) {
      this.player.src(this.props.media.audioUrl)
      this.setState({ media: this.props.media })
    }

    if (this.props.time && this.state.time.timestamp !== this.props.time.timestamp) {
      this.player.currentTime(this.props.time.start)
      this.player.play()
      this.setState({time: this.props.time})
    }
  }


  // TODO: move this out of this component
  async saveUserCurrentTime() {
    const audioCanvasCurrentTime = this.player.currentTime()
    if (audioCanvasCurrentTime <= 0) { return }

    const videoId = this.props.media?.id
    await videoLastViewedSet(videoId, audioCanvasCurrentTime)
  }

  handleKeyDown(event){
  
    if(event.key === ' '){
      event.preventDefault();//to prevent scroll down
      if (this.player.paused()) {
        this.player.play();
      }
      else {
        this.player.pause();
      }
    }
  }

  render() {
    return <>
      <IntervalTimer
        timeout={5000}
        callback={() => { if (!this.player.paused()) { this.saveUserCurrentTime() } }}
        enabled={true}
        repeat={true} />
      <audio
        className="video-js vjs-big-play-centered vjs-fluid"
        // poster = "https://img.freepik.com/free-vector/modern-music-event-poster-template_1361-1292.jpg?size=626&ext=jpg"
        ref={node => this.videoNode = node} />
    </>
  }
}
