import axios from 'axios'
import { getToken, logout } from './auth'

export const apiUrl = process.env.REACT_APP_API_URL

axios.defaults.baseURL = apiUrl
axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 && error.response.status < 500

  // unauthorized, no login
  if (error?.response?.status === 401) {
    // reset token if exists
    getToken() || logout()

    if (window.location.pathname.indexOf('/login') !== 0)
      window.location.assign("/login")
  }

  // forbidden, authorized but not allowed on a specific endpoint
  if (error?.response?.status === 403 && getToken()) {
    window.location.assign("/")
  }


  if (axios.isCancel(expectedError)) return

  if (!expectedError) {
    //TODO: logger
    console.log(error)

    //TODO: create toast when client side error occurs
  }

  return Promise.reject(error)
})

// TODO: set a JWT
// function setJWT(jwt) {
//   axios.defaults.headers.common["x-auth-token"] = jwt
// }

export default {
  get: axios.get,
  put: axios.put,
  post: axios.post,
  delete: axios.delete,
  patch: axios.patch
  //TODO: setJWT
}
