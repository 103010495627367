import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'


const NextVideo = ({nextVideo,userIsAllowedToView}) =>{
      const viewNextVideo = (nextVid) => {
        if (userIsAllowedToView) {
          window.location.assign(`/video/${nextVid.video.id}`)
        }
      }


    return <div>
        <Row>
            <Col lg="12"><hr /></Col>
        </Row>
        <Row>
            <Col lg={{ span: 6, offset: 6 }}>
                <p className="text-right">nächstes Video {'>'} </p>
                <Card onClick={() => viewNextVideo(nextVideo)} style={{ 'cursor': 'pointer', 'opacity': userIsAllowedToView ? '1' : '0.5' }}>
                <div style={{ height: '200px', overflow: 'hidden' }}>
                    <Card.Img className="w-100" variant="top" src={nextVideo.video.thumbnail} />
                </div>
                <Card.Body>
                    <div>
                    {!userIsAllowedToView &&
                        <p className="text-danger">Kein Zugriff</p>
                    }
                    </div>
                    <Card.Title>{nextVideo.video.title}</Card.Title>
                    {nextVideo.video.description}
                </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
}

export default NextVideo