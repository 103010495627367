import React from 'react';
import NavigationBar from '../nav/navigationBar';

const Header = () => {
  const getLogo = () => {
    const userType = localStorage.getItem('imv-utype')

    if (userType === 'EFC')
      return '/static/images/logo_efc.png'
    else if (userType === 'PT')
      return '/static/images/logo_pt.png'
    else
      return '/static/images/logo_efc_2.png'
  }

  return <>
    <div className="header w-100 text-center p-3">
      <a href="/" className="center-xy" style={{ textDecoration: 'none' }}>
        <img className="logo pr-3" src={getLogo()} />
        <div className="text-left pl-4">
          <h3 className="mb-0 text-primary">Online-Portal</h3>
          <h6 className="text-muted">Dipl.-Psych. Felix Zegelman</h6>
        </div>
      </a>
    </div>

    <NavigationBar />
  </>
}

export default Header
