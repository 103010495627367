
import React from 'react'
import {createStore} from 'redux';
import { Provider } from 'react-redux'
import reducers from '../reducers';

export const store = createStore(reducers);

export const ContextProvider = (props) => {
    return (
        <Provider store={store}>
            {props.children}
        </Provider>
    )
}