import http, { apiUrl } from './http'
import { getToken } from './auth'

export const getVideoGroupList = () => {

    return http.get(apiUrl + 'videogroup', {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

export const getCurrentUserVideoGroups = () => {

    return http.get(apiUrl + `videogroup/currentuser/`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

export const getUserVideoGroups = (id) => {
    return http.get(apiUrl + `videogroup/user/${id}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

export const createVideoGroup = (videogroup) => {

    return http.post(apiUrl + 'videogroup', videogroup, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    });
}

export const addVideoToGroup = (groupId, videoId) => {

    return http.post(apiUrl + `videogroup/addvideo/${groupId}/${videoId}`, {}, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    });
}

export const addVideosToGroup = (groupId, videoIds) => {

    return http.post(apiUrl + `videogroup/addvideos/${groupId}/${videoIds}`, {}, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    });
}

export const renameVideoGroup = (groupId, newName) => {

    return http.patch(apiUrl + `videogroup/${groupId}/new-name/${encodeURI(newName)}`, {}, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

export const editOrderRank = (groupId, newRank) => {

    return http.patch(apiUrl + `videogroup/${groupId}/new-order-rank/${newRank}`, {}, {
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
}

export const deleteVideoToGroup = (groupId, videoId) => {

    return http.delete(apiUrl + `videogroup/${groupId}/delete-video/${videoId}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    });
}

export const addUserToGroup = (groupId, userId) => {

    return http.post(apiUrl + `videogroup/adduser/${groupId}/${userId}`, {}, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    });
}

export const deleteUserToGroup = (groupId, userId) => {

    return http.delete(apiUrl + `videogroup/${groupId}/delete-user/${userId}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    });
}

export const deleteVideoGroup = (groupId) => {

    return http.delete(apiUrl + `videogroup/${groupId}`, {
        headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Content-Type': 'application/json'
        }
    });
}
