export const timeFormatToSeconds = (timeFormat) =>{

    const splits = timeFormat.split(':');
    const hours = splits[0];
    const minutes = splits[1];
    let seconds = parseInt(splits[2]);

    const hoursInt = parseInt(hours);
    if(hoursInt > 0){
        seconds+=hoursInt*60*60;
    }

    const minutesInt = parseInt(minutes);
    if(minutesInt > 0){
        seconds+=minutesInt*60;
    }

    return seconds;
}