import React from 'react';
import { Container } from 'react-bootstrap';

const Impressum = () => {
  return (
    <Container>
      <h1 style={{ color: '#a51916' }}>Emotionsfokussiertes Coaching Zegelman</h1>
      <div style={{ color: '#666666', fontSize: '18px' }}>
        <p>
          <strong>Inhaber:</strong><br />
          <span>Dipl.-Psych. Felix Zegelman</span>
        </p>
        <p>
          <span>Hauptstraße 25a</span><br />
          <span>65843 Sulzbach (Taunus)</span>
        </p>
        <p>
          <strong>Tel.: </strong>
          <span>0 61 96 / 80 17 839</span><br />
          <strong>E-Mail: </strong>
          <span>info@changing-emotions.de</span>
        </p>
        <p>
          <strong>Gesetzliche Berufsbezeichnung: </strong>
          <span>Approbation als Psychologischer Psychotherapeut, Landesprüfungsamt für Heilberufe in Hessen, Diplom-Psychologe, Johann Wolfgang Goethe-Universität in Frankfurt am Main</span>
        </p>
        <p>
          <strong>Staat, in dem die Berufsbezeichnung verliehen wurde: </strong>
          <span>Bundesrepublik Deutschland</span>
        </p>
        <div id="haftungshinweis">
          <p>
            <strong>Haftungshinweis:</strong> Diese Seite enthält Links zu externen Internetseiten. Trotz sorgfältiger Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt verlinkter Seiten sind ausschließlich deren Betreiber verantwortlich.
                    </p>
        </div>
        <div id="datenschutz-link">
          <p><a href='/privacy' style={{ color: '#a51916' }}>Hinweise zum Datenschutz</a></p>
        </div>
        <p />
      </div>
    </Container>
  )
}

export default Impressum
