import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, ListGroup, Modal, Row } from 'react-bootstrap'
import AudioPlayer from '../components/video/audioPlayer'
import NextVideo from '../components/video/nextVideo'
import PlaybackModeSwitcher from '../components/video/playbackModeSwitcher'
import VideoPlayer from '../components/video/videoPlayer'
import { getCurrentUser, getCurrentUserPlaybackMode, isCurrentUserAdmin } from '../services/auth'
import { deleteVideoSection, editVideoSection, getNextVideo, getVideo, hideVideoSection } from '../services/videos'
import { timeFormatToSeconds } from '../utils/time'

const Video = (props) => {
  const user = getCurrentUser()
  const isAdmin = isCurrentUserAdmin()
  const id = props.match.params.id

  const [video, setVideo] = useState(null)
  const [videoSections, setVideoSections] = useState(null)
  const [startTime, setStartTime] = useState({start: 0, timestamp: 0})
  const [nextVideo, setNextVideo] = useState(null)
  const [editSectionModal, setEditSectionModal] = useState(false)
  const [sectionToEdit, setSectionToEdit] = useState('')
  const [sectionToReplace, setSectionToReplace] = useState('')
  const [playbackMode, setPlaybackMode] = useState(getCurrentUserPlaybackMode())
  const [imageUrls, setImageUrls] = useState(null)
  const [folderNames, setFolderNames] = useState()
  const [imageGalleryModal, setImageGalleryModal] = useState(false)
  const [folder, setFolder] = useState('')

  useEffect(() => {
    const getMedia = async () => {
      const { data: _video } = await getVideo(id)
      setVideo(_video.video)
      setVideoSections(_video.video.sections)
      setStartTime({start: _video.userPrevVideoTime, timestamp: (new Date()).getTime()})
      setImageUrls(_video.video.imageUrls)
      getGroupedImages(_video.video.images)


      if (playbackMode === 'audio') {
        setVideoSections(_video.video.audioSections)
      }

      const { data: _nextVideo } = await getNextVideo(id)
      setNextVideo(_nextVideo)
    }

    getMedia()
  }, [])


  const setVidSection = (time) => {
    setStartTime({start: timeFormatToSeconds(time), timestamp: (new Date()).getTime()})
  }

  const handleEditSectionModal = (sectionTitle) => {
    setSectionToEdit(sectionTitle)
    setEditSectionModal(true)
  }

  const handleSaveEditSection = async () => {
    if (isAdmin) {
      const payload = {
        'id': video.id,
        'oldVideoSectionName': sectionToEdit,
        'newVideoSectionName': sectionToReplace
      }

      try {
        const { data: _video } = await editVideoSection(payload)
        setEditSectionModal(false)
        setSectionToEdit('')
        setSectionToReplace('')
        setVideo(_video)
        setVideoSections(_video.sections)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleDeleteSection = async (sectionTitle) => {
    if (isAdmin) {
      try {
        const { data: result } = await deleteVideoSection(video.id, sectionTitle)

        setVideo(result)
        setVideoSections(result.sections)
      } catch (e) {
        console.log(e)
      }
    }
  }

  const hideVideoSectionToggle = async (sectionTitle, hide) => {
    const payload = {
      'id': video.id,
      'title': sectionTitle,
      'hide': hide
    }

    if (isAdmin) {
      try {
        const { data: result } = await hideVideoSection(payload)
        setVideo(result)
        setVideoSections(result.sections)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const slideToggleSections = () => {
    const element = document.querySelector('.listgroup-wrapper')
    const elementHeight = element.offsetHeight

    if (elementHeight > 0) {
      element.style.height = '0px'
      document.querySelector('.sections-toggle').classList.remove('btn-secondary')
      document.querySelector('.sections-toggle').classList.add('btn-outline-secondary')
    } else {
      element.style.height = `${element.scrollHeight}px`
      document.querySelector('.sections-toggle').classList.remove('btn-outline-secondary')
      document.querySelector('.sections-toggle').classList.add('btn-secondary')
    }
  }

  const userIsAllowedToView = (nextVid) => {
    return nextVid.userAllowed || isAdmin
  }

  const getGroupedImages = async (images) => {
    if (!images) return

    let fN = images.map(i => {
      if (i.folderName === '') {
        return 'Bilder'
      } else {
        return i.folderName
      }

    })

    //remove duplicate foldernames
    fN = fN.filter((item, index) => {
      return fN.indexOf(item) === index
    })


    const groupedImages = fN.map(f => {
      let imagesOfThisFolder = images.filter(i => i.folderName === f)

      if (f === 'Bilder') {
        imagesOfThisFolder = images.filter(i => !i.folderName)
      }

      return { 'folderName': f, 'images': imagesOfThisFolder }
    })

    setFolderNames(groupedImages)
  }

  const showGalleryModal = (folder) => {
    setFolder(folder)
    setImageGalleryModal(true)
  }

  const getImagePosition = (imagePosition) => {
    if (imagePosition === 'left' || imagePosition === 'right') {
      return ' ip-' + imagePosition
    } else {
      return ' ip-center'
    }
  }

  const numberOfImages = (images) => {
    let numberOfImagesClassname = 'img-thumbnails'

    // if(images.length === 2){
    //   numberOfImagesClassname += '-2'
    // }
    if (images.length >= 2) {
      numberOfImagesClassname += '-34'
    }

    return numberOfImagesClassname + ' '
  }

  const renderNoFolderImages = (bilder) => {
    return <>
      {(bilder && bilder.images) &&
        bilder.images.map((image) => (
          <Col key={'Bilder' + image.imageName} xs='6' md='4' lg='3' className='center-xy'>
            <div className='text-center'>
              <div className='gallery'>
                <a href={image.imageUrl} target="_blank">
                  <img className={numberOfImages([image]) + getImagePosition(image.imagePosition)} src={image.imageUrl} alt="default" />
                </a>
              </div>

              <div><span className="cursor-pointer text-primary ">{image.imageName}</span></div>
            </div>
          </Col>
        ))
      }
    </>
  }

  const cropLabel = (imageName) => {
    let croppedName = ''

    if (imageName.length < 15) return imageName


    const splitImages = imageName.split(' ')

    Object.keys(splitImages).forEach((index) => {
      if (splitImages[index].length < 14) {
        croppedName += splitImages[index] + ' '
      } else {
        var chunks = []

        for (var i = 0, charsLength = splitImages[index].length; i < charsLength; i += 14) {
          chunks.push(splitImages[index].substring(i, i + 14))
        }

        croppedName += chunks.join("- ")
      }
    })


    return croppedName
  }

  return <>
    {/* Edit Section Modal */}
    <Modal id="edit-section-modal"
      show={editSectionModal}
      onHide={() => setEditSectionModal(false)}
      aria-labelledby="edit-section-modal"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="edit-section-modal">
          Edit Section Title
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Control type="text" placeholder="Enter section title" defaultValue={sectionToEdit} onChange={(e) => setSectionToReplace(e.target.value)} />
        </Form.Group>
        <div className="text-right">
          <Button className="px-4" onClick={() => handleSaveEditSection()}>Save</Button>
        </div>
      </Modal.Body>
    </Modal>


    {/* Image Gallery Modal */}
    <Modal
      show={imageGalleryModal}
      onHide={() => setImageGalleryModal(false)}
      aria-labelledby="images-modal"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="images-modal">
          {folder.folderName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          {folder.images &&
            <div>
              <Row className='img-gallary-container p-3'>
                {folder.images.map((image) => (
                  <Col key={folder.folderName + image.imageUrl} xs='4' md='4' lg='3' className='center-xy mb-3'>
                    <div className='modal-gallery text-center'>
                      <a href={image.imageUrl} target="_blank">
                        <img className={getImagePosition(image.imagePosition)} src={image.imageUrl} alt="default" />
                      </a>
                      <span className="cursor-pointer text-primary ">{cropLabel(image.imageName)}</span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          }
        </Form.Group>
      </Modal.Body>
    </Modal>


    {
      video && <Row>
        <Col lg="8">
          <PlaybackModeSwitcher />
          {playbackMode === 'video'
            ? <VideoPlayer media={video} time={startTime} /> : <AudioPlayer media={video} time={startTime} />}

          <h3 className='pt-3'>{video.title}</h3>
          <p>{video.description}</p>

          {folderNames &&  <div>
            <Row>
              <Col lg="12"><hr /></Col>
            </Row>

            <Row className='img-gallary-container p-3'>
              {renderNoFolderImages(folderNames.filter(f => f.folderName === 'Bilder')[0])}

              {folderNames.filter(f => f.folderName !== 'Bilder').map((folder) => (
                <Col key={folder.folderName} xs='6' md='4' lg='3' className='center-xy mb-3'>

                  <div className='text-center'>
                    <div className='gallery' onClick={() => showGalleryModal(folder)}>
                      {folder.images.slice(0, 4).map((image) => (
                        <img key={folder.folderName + image.imageUrl} className={numberOfImages(folder.images) + getImagePosition(image.imagePosition)} src={image.imageUrl} alt="default" />
                      ))}
                    </div>
                    <div>
                      <span className="cursor-pointer text-primary ">{folder.folderName}</span>
                    </div>
                  </div>

                </Col>
              ))}
            </Row>
          </div>}

          {/* display on mobile only */}
          <Card className="mb-4 d-block d-lg-none sections">
            <Card.Body>
              <div className="position-relative d-flex align-items-center" style={{ height: '40px' }}>
                <Card.Title className="mb-0">
                  Abschnitte

                  <Button variant="outline-secondary" className="sections-toggle" onClick={() => slideToggleSections()}>
                    <i className="fas fa-bars"></i>
                  </Button>
                </Card.Title>
              </div>
              {videoSections ? <div className="listgroup-wrapper">
                  <ListGroup variant="flush">
                    {videoSections.map((section, index) => (
                      <div key={'vidsec_' + index}>{
                        (!section.hidden || isAdmin) &&
                        <ListGroup.Item className="px-2" key={'vidsec_' + index}>
                          <span style={{ 'opacity': section.hidden ? '0.5' : '1' }} className="cursor-pointer"
                            onClick={() => setVidSection(section.time)}>{section.title}</span>

                          {isAdmin &&
                            <>
                              <i title="edit section" className='fas fa-pencil-alt text-muted ml-1 cursor-pointer' onClick={() => handleEditSectionModal(section.title)}></i>
                              <small className="text-muted px-1" style={{ 'pointerEvents': 'none' }}>|</small>
                              <i title="delete section" className='fas fa-times-circle text-muted cursor-pointer' onClick={() => { handleDeleteSection(section.title) }}></i>

                              {section.hidden && section.hidden == true ?
                                <span onClick={() => hideVideoSectionToggle(section.title, false)} className="cursor-pointer text-primary" style={{ 'position': 'absolute', 'right': '6px' }}><small>Unhide</small></span>
                                :
                                <span onClick={() => hideVideoSectionToggle(section.title, true)} className="cursor-pointer text-danger" style={{ 'position': 'absolute', 'right': '6px' }}><small>Hide</small></span>
                              }
                            </>
                          }
                        </ListGroup.Item>
                      }</div>
                    ))}
                  </ListGroup>
                </div>
                :
                <p><i>Für dieses Video sind keine Abschnitte hinterlegt</i></p>
              }
            </Card.Body>
          </Card>

          {nextVideo && <NextVideo nextVideo={nextVideo} userIsAllowedToView={userIsAllowedToView(nextVideo)} />}
        </Col>


        {/* display on desktop only */}
        <Col className="d-none d-lg-block">
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Abschnitte</Card.Title>
              {videoSections ? <ListGroup variant="flush">
                  {videoSections.map((section, index) => (
                    <div key={'vidsec_' + index}>{
                      (!section.hidden || isAdmin) &&
                      <ListGroup.Item className="px-2" key={'vidsec_' + index}>
                        <span style={{ 'opacity': section.hidden ? '0.5' : '1' }} className="cursor-pointer"
                          onClick={() => setVidSection(section.time)}>{section.title}</span>

                        {isAdmin &&
                          <>
                            <i title="edit section" className='fas fa-pencil-alt text-muted ml-1 cursor-pointer' onClick={() => handleEditSectionModal(section.title)}></i>
                            <small className="text-muted px-1" style={{ 'pointerEvents': 'none' }}>|</small>
                            <i title="delete section" className='fas fa-times-circle text-muted cursor-pointer' onClick={() => { handleDeleteSection(section.title) }}></i>

                            {section.hidden && section.hidden == true ?
                              <span onClick={() => hideVideoSectionToggle(section.title, false)}
                                className="cursor-pointer text-primary" style={{ 'position': 'absolute', 'right': '6px' }}>
                                <small>Unhide</small>
                              </span>
                              :
                              <span onClick={() => hideVideoSectionToggle(section.title, true)}
                                className="cursor-pointer text-danger" style={{ 'position': 'absolute', 'right': '6px' }}>
                                <small>Hide</small>
                              </span>
                            }
                          </>
                        }
                      </ListGroup.Item>
                    }</div>
                  ))}
                </ListGroup>
                :
                <p><i>Für dieses Video sind keine Abschnitte hinterlegt</i></p>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    }
  </>
}

export default Video
