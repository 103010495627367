import React, { useEffect } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { getCurrentUser, logout } from '../../services/auth'
import { secondTicksToDateTime } from '../../utils/dateTime'

const NavigationBar = ({ container, userRole }) => {
  const user = getCurrentUser()
  const userType = localStorage.getItem('imv-utype')


  useEffect(() => {
    if (!user) return

    var expiryDate = secondTicksToDateTime(user.AccountExpiryDate)
    if (expiryDate && new Date(expiryDate) <= new Date() && user.Roles !== 'Admin') {
      logoutUser()
    }
  }, [])


  const logoutUser = () => {
    logout()
    window.location.assign('/')
  }


  return <>
    <Navbar className="navbar-dark" expand="lg" sticky="top">
      <Container fluid={container === 'fluid'}>
        <Navbar.Brand href="/">
          {user && userRole === 'admin' && <>
            Online-Portal
            {user.Roles.toLowerCase() !== 'admin' &&
              <img src={userType === 'PT' ? '/static/images/logo_pt.png' : '/static/images/logo_efc.png'}
              className="nav-logo ml-1" alt="logo" />
            }
          </>}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {user && <>
              <Nav.Link href="/">Menü</Nav.Link>
              {user.Roles.toLowerCase() === 'admin' && <>
                <Nav.Link href="/videos">Videos</Nav.Link>
                <Nav.Link href="/admin/videos">Dashboard</Nav.Link>
              </>}
              {/* <Nav.Link href="/profile">Profile</Nav.Link> */}
              <Nav.Link href="#" onClick={() => logoutUser()}>Logout</Nav.Link>
            </>}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
}

export default NavigationBar
