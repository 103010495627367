import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap'
import { toast } from 'react-toastify'

const BrowserCompatibilityAlert = () => {

  const [show, setShow] = useState(false);

  useEffect(() => {

    if (!isChromeOrSafari()) {

      setShow(true)

      //if (!localStorage.getItem('imv-browserwarn')) {
      //  localStorage.setItem('imv-browserwarn', 'warn')
        // toast.info('Für die optimale Darstellung wird die Verwendung des Safari- oder des Chrome-Browsers empfohlen.', {
        //   autoClose:10000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // })
      //}
    }

  },[])

  const isChromeOrSafari = () => {

    if(!navigator.userAgentData){
      if (navigator.userAgent.indexOf("Chrome") == -1 && navigator.userAgent.indexOf("Safari") == -1 ) {
        return false;
      }
      //is opera
      else if((navigator.userAgent.match(/Opera|OPR\//) ? true : false)){
        return false;
      }
      else return true;
    }

    var hasChrome = navigator.userAgentData.brands.find(x => x.brand.toLowerCase().includes("google chrome"));
    var hasSafari = navigator.userAgentData.brands.find(x => x.brand.toLowerCase().includes("safari"));
    return hasChrome || hasSafari;
  }

  return <>
    {
      show &&
      <Alert variant='warning' className="text-center mt-0 mb-3 fade show" //onClose={() => setShow(false)} dismissible
      >
        Für die optimale Darstellung wird die Verwendung des Safari- oder des Chrome-Browsers empfohlen.
      </Alert>
    }
  </>
}

export default BrowserCompatibilityAlert
