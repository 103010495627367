import React from 'react'
import {Container, Nav, NavbarBrand, Navbar} from 'react-bootstrap'


const Footer = () => {

    return (
        
        <>
            <div>  
            <Navbar style={{color:'#a51916'}}>
                <Container>
                <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                        <Nav.Link href="/privacy" style={{ color:'white'}}>Datenschutz</Nav.Link>
                        </li>
                        <li>
                        <Nav.Link href="/impressum" style={{ color:'white'}}>Impressum</Nav.Link>
                        </li>
                    </ul>
                </div>                        
                </Container>
            </Navbar>
        </div>
        </>
    )
}

export default Footer