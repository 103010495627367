import React from 'react'
import IntervalTimer from 'react-interval-timer'
import { videoLastViewedSet } from '../../services/userVideoViewings'
import { IS_IOS, IS_IPHONE } from '../../utils/iosDetection'
import './mobileUi'

const videojs = window.videojs
const IS_MOBILE = videojs.browser.IS_ANDROID || IS_IOS

export default class VideoPlayer extends React.Component {
  constructor() {
    super()
    this.state = { media: {url: ''}, time: {start: 0, timestamp: 0} }
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    // Create an instance of video.js
    this.player = videojs(this.videoNode, {
      // hide additional controls on mobile
      controlBar: {
        pictureInPictureToggle: !IS_MOBILE,
        volumeControl: false
      },
      controls: true,
      // use native controls for iPhone
      // nativeControlsForTouch: IS_IPHONE,
      autoplay: true,
      preload: 'auto'
    })
    this.player.on('pause', () => { this.saveUserCurrentTime() })


    // Activate plugins
    this.player.landscapeFullscreen({
      fullscreen: {
        enterOnRotate: true,
        alwaysInLandscapeMode: false,
        iOS: IS_IPHONE
      }
    })
    this.player.mobileUi({
      touchControls: {
        seekSeconds: 10,
        tapTimeout: 300,
        disableOnEnd: false
      }
    })
    this.player.extraButtons({
      quickBackward: { seconds: 10 },
      quickForward: { seconds: 10 },
      // TODO: not working properly
      qualitySelect: [
        { bandwidth: 524288, name: "SD" },
        { bandwidth: 1048576, name: "HD" },
        { bandwidth: 2097152, name: "QHD" },
        { bandwidth: 4194304, name: "UHD" }
      ], // only works when source is hls(m3u8)
      playbackRateSelect: [1.0, 1.2, 1.4, 1.6, 2.4]
    })

    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate() {
    if (this.props.media && this.props.media.url &&
      this.state.media.url !== this.props.media.url) {
      const source = (this.props.media.url + "") // +"" cast into string
        .replace(/#t=\d{2}(:\d{2})*/g, '') // remove timestamp at the end of URL (if any)

      this.player.src(source)
      this.setState({ media: this.props.media })
    }
    
    if (this.props.time && this.state.time.timestamp !== this.props.time.timestamp) {
      this.player.currentTime(this.props.time.start)
      this.player.play()
      this.setState({time: this.props.time})
    }
  }


  // TODO: move this out of this component
  async saveUserCurrentTime() {
    const videoCanvasCurrentTime = this.player.currentTime()

    if (videoCanvasCurrentTime <= 0) {
      return
    }

    const videoId = this.props.media?.id
    await videoLastViewedSet(videoId, videoCanvasCurrentTime)
  }

  handleKeyDown(event){
  
    const editSectionModal = document.getElementById("edit-section-modal");
    if(!editSectionModal && event.key === ' '){
      event.preventDefault();//to prevent scroll down
      if (this.player.paused()) {
        this.player.play();
      }
      else {
        this.player.pause();
      }
    }
  }

  render() {
    return <>
      <IntervalTimer
        timeout={5000}
        callback={() => { if (!this.player.paused()) { this.saveUserCurrentTime() } }}
        enabled={true}
        repeat={true} />

      <video id="video-player"
        className="video-js vjs-big-play-centered vjs-fluid"
        ref={node => this.videoNode = node}/>
    </>
  }
}
