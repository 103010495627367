// NOTE: do not add other hooks here, use App.js instead
// NOTE: import IE polyfill first
import 'react-app-polyfill/ie11'

import * as serviceWorker from './serviceWorker'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ContextProvider } from './redux/store';
import './assets/css/style.css'
import './assets/css/vstyle.css'
import './assets/css/mobile.css'
import './index.css'

ReactDOM.render(
  <ContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ContextProvider>,
  document.getElementById('root')
)
serviceWorker.unregister()
