import React, { useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap'
import Spinner from '../../components/spinner'
import { getCurrentUser } from '../../services/auth'
import http from '../../services/http'

const LoginForgetPassword = () => {
  const [username, setUsername] = useState()
  const [loadingError, setLoadingError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  // check if user is logged in, redirect back to home page if so
  if (getCurrentUser()) { window.location.assign('/videos') }


  const resetPassword = (e) => {
    e.preventDefault()
    if (isLoading) return

    setLoadingError(false)
    setIsLoading(true)
    setIsSuccess(false)

    http.post('login/forget-password', { username })
      .then(() => setIsSuccess(true))
      .catch((ex) => setLoadingError(ex.message || "Unbekannter Fehler."))
      .finally(() => setIsLoading(false))
  }


  return <section>
    <Col>
      <Container>
        <Row className='justify-content-center'>
          <Col lg='6'>
            {isSuccess && <Alert variant="success">Erfolgreich! Bitte prüfen Sie Ihre Email für weitere Anweisungen.</Alert>}
            {loadingError && <Alert variant="danger">Es ist ein Fehler aufgetreten! {loadingError}</Alert>}
            <Form onSubmit={resetPassword}>
              <Card className='p-4 mb-4'>
                <h4 className="text-center">Passwort zurücksetzen</h4>
                <p className="mb-5">Geben Sie Ihren Benutzernamen an und wir senden Ihnen eine Email mit dem Link zum Zurücksetzen Ihres Passworts.</p>

                <Form.Group controlId='login-username'>
                  <Form.Label>Benutzername oder Email</Form.Label>
                  <Form.Control required
                    type='text' name='username'
                    placeholder='Benutzername oder Email'
                    defaultValue={username}
                    onChange={(event) => setUsername(event.target.value)} />
                </Form.Group>

                <Button className='mb-3' type="submit">
                  {isLoading ? <Spinner /> : <>zurücksetzen</>}
                </Button>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Col>
  </section>
}

export default LoginForgetPassword
