import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminHolder from './components/holder/adminHolder'
import DefaultHolder from './components/holder/defaultHolder'

function App() {

  return <>
    <Switch>
      <Route path="/admin" component={AdminHolder} />
      <Route component={DefaultHolder} />
    </Switch>
    <div><ToastContainer /></div>
  </>
}

export default App
