import React, { useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import Spinner from '../components/spinner'
import { getCurrentUser, login } from '../services/auth'
import { getLastViewedVideoTime } from '../services/userVideoViewings'

const Login = () => {
  const [username, setUsername] = useState()
  const [password, setPassword] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)


  const loginUser = async (e) => {
    e.preventDefault()
    if (isLoading) return

    if (!username) {
      setError('Benutzername wird benötigt')
      return
    }

    if (!password) {
      setError('Passwort wird benötigt')
      return
    }

    try {
      setIsLoading(true)
      const user = await login(username, password)
      if (user.roles?.toLowerCase() === 'admin') {
        window.location.assign('/admin')
      } else if (user.accountExpired) {
        setError('Die Zugangszeit des Accounts ist abgelaufen.')
      } else {
        redirectUser(user)
        return
      }
    }
    catch (e) {
      if (e.message.includes('401'))
        setError('Falsches Passwort oder Benutzername.')
      else {
        setError('Fehler aufgetreten: Bitte versuchen Sie es erneut.')
        console.error(e.message);
      }
    }
    setIsLoading(false)
  }

  const redirectUser = async (user) => {
    const videoLastViewed = await getLastViewedVideoTime()

    if (videoLastViewed.data.lastView !== null) {
      window.location.assign('/video/' + videoLastViewed.data.lastView.videoId)
    } else {
      window.location.assign('/videos')
    }
  }


  // checking if user is already logged in
  const user = getCurrentUser()
  if (user) { redirectUser(user) }


  return <section>
    <Col>
      <Container>
        <Row className='justify-content-center'>
          <Col lg='6'>
            <Form onSubmit={loginUser}>
              {error &&
                <Alert variant='danger' className="text-center">
                  <strong>Anmeldung fehlgeschlagen: {error}</strong>
                </Alert>
              }
              <Card className='p-4 mb-4'>
                <h4 className="text-center">Anmelden</h4>
                <Form.Group controlId='login-username'>
                  <Form.Label>Benutzername</Form.Label>
                  <Form.Control required
                    type='text' name='username'
                    placeholder='Benutzername'
                    onChange={(event) => setUsername(event.target.value)} />
                </Form.Group>
                <Form.Group className='mb-5' controlId='login-password'>
                  <Form.Label>Passwort</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control required
                      className="border-right-0"
                      type={showPassword ? 'text' : 'password'} name='password'
                      placeholder='Passwort'
                      onChange={(event) => setPassword(event.target.value)} />
                    <InputGroup.Append>
                      <InputGroup.Text className="bg-white border-left-0 text-muted">
                        <i className={`far fa-eye${showPassword ? '' : '-slash'} cursor-pointer`} onClick={() => setShowPassword(!showPassword)}></i>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>

                <Button className='mb-3' type="submit">
                  {isLoading ? <Spinner /> : <>Login</>}
                </Button>

                <p className="text-center"><a href="/login/forget-password">Passwort vergessen?</a></p>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Col>
  </section>
}

export default Login
