import React from 'react'

const Spinner = () => {
  return <div>
    <span className='spinner-grow spinner-grow-sm mr-2' role='status' aria-hidden='true' style={{ backgroundcolor: '#770d29' }} ></span>
    Wird geladen...
  </div>
}

export default Spinner
