import { getHttpAuthHeaders, getToken } from './auth';
import http, { apiUrl } from './http';

export const getUsers = () => {
  return http.get(apiUrl + 'users', getHttpAuthHeaders())
}

export const searchUsersByName = (name) => {
  return http.get(apiUrl + 'users/' + 'search-by-names/' + name, getHttpAuthHeaders())
}

export const createUser = (user) => {
  return http.post(apiUrl + 'users/', user, getHttpAuthHeaders())
}

export const removeUser = (id) => {
  return http.delete(apiUrl + 'users/' + id, getHttpAuthHeaders())
}

export const addDaysToExpiration = (userId, days) => {
  return http.patch(apiUrl + 'users/' + userId + '/update-expiration/' + days, {}, getHttpAuthHeaders())
}


export const sendMailUserInfo = (email, fullname, signature, emailBody) => {
  return http.post(apiUrl + 'users/send-mail-information/' , { email, fullname, signature, emailBody } , getHttpAuthHeaders())
}
