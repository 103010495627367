import React from 'react'
import { Container } from 'react-bootstrap'
import { Redirect, Route, Switch } from 'react-router-dom'
import NotFound from '../../pages/404'
import Impressum from '../../pages/impressum'
import Login from '../../pages/login'
import LoginForgetPassword from '../../pages/login/forgetPassword'
import LoginResetPassword from '../../pages/login/resetPassword'
import Privacy from '../../pages/privacy'
import Profile from '../../pages/profile'
import Video from '../../pages/video'
import Videos from '../../pages/videos'
import Footer from '../footer/footer'
import Header from '../header/header'
import BrowserCompatibilityAlert from '../browserCompatibilityAlert'
import ProtectedRoute from '../redirect/protectedRoute'

const DefaultHolder = () => {
  return <>
    <Header />
    <Container style={{ minHeight: 800 }}>
    
      <section className="p-3">
        <BrowserCompatibilityAlert/>
        <Switch>
          <ProtectedRoute
            path="/profile"
            render={() => <Profile />} />
          <ProtectedRoute
            path="/videos"
            render={props => <Videos {...props} />} />
          <ProtectedRoute
            path="/video/:id"
            render={props => <Video {...props} />} />

          <Route path="/login/forget-password" component={LoginForgetPassword} />
          <Route path="/login/reset-password" component={LoginResetPassword} />
          <Route path="/login" component={Login} />

          <Redirect from="/" exact to="/videos" />
          <Route path="/impressum" component={Impressum} />
          <Route path="/privacy" component={Privacy} />

          <Route path="/not-found" component={NotFound} />
          <Redirect to="/not-found" />
        </Switch>
      </section>
    </Container>
    <Footer />
  </>
}

export default DefaultHolder
