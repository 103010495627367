import React, { useState } from 'react'
import { getCurrentUser, getCurrentUserPlaybackMode } from '../services/auth'
import { Alert, Col, Row, Form } from 'react-bootstrap'
import Spinner from '../components/spinner'
import PlaybackModeSwitcher from '../components/video/playbackModeSwitcher'


const Profile = () => {


  return <>
    <PlaybackModeSwitcher />
  </>
}

export default Profile
