import http, { apiUrl } from './http'
import { getToken } from './auth'

export const getValidityDaysOptions = () => {
  return http.get(apiUrl + 'configs/validityday-options', {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const addValidityDaysOptions = (days) => {
  return http.post(apiUrl + 'configs/add-validityday-option/' + days, {}, {
    headers: {
      'Authorization': 'Bearer ' + getToken(),
      'Content-Type': 'application/json'
    }
  })
}
