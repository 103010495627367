const keyboardEvent = (state = {}, action) => {
    switch (action.type) {
      case 'STORE_KEYBOARD_EVENT':
        state = action.keyboardEvent
        return state
  
      default: return state
    }
  }
  
  export default keyboardEvent
  