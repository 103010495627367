import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Spinner from '../../components/spinner'
import { sendMailUserInfo } from '../../services/users'
import MailTemplatePT, { emailTemplateBody, signatureEFC, signaturePT } from '../admin/emailTemplate'

const EmailModal = ({ setDisplayEmailBodyModal, displayEmailBodyModal, receiverEmail, receiverFullName, userType, userPassword, username, userDuration, setFirstParagh, firstParagh }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSendMail = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      let emailTemplateBodyTemp = (firstParagh.replace(/([>\r\n]?)(\r\n|\n\r|\r|\n)/g, "<br /><br />") + emailTemplateBody())
        .replace("{username}", username)
        .replace("{password}", userPassword)
        .replace("{daysvalidity}", userDuration.replace("days","").replace("day",""))

      let signature = userType === 'PT' ? signaturePT() : signatureEFC()

      await sendMailUserInfo(receiverEmail, receiverFullName, signature, emailTemplateBodyTemp)
      toast.success('Email erfolgreich an ' + receiverEmail + ' gesendet.')
    } catch (ex) {
      toast.error('Email nicht gesendet.')
      console.error(ex)
    }

    setIsLoading(false)
    setDisplayEmailBodyModal(false)
  }

  return <Modal show={displayEmailBodyModal} onHide={() => setDisplayEmailBodyModal(false)}
    aria-labelledby="account-information-for-email-modal" centered>
    <Form id="account-information-for-email-form" noValidate onSubmit={handleSendMail}>
      <Modal.Header closeButton>
        <Modal.Title>
          Email
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <MailTemplatePT
          receiverFullName={receiverFullName}
          userPassword={userPassword}
          username={username}
          userDuration={userDuration}
          firstParagh={firstParagh}
          setFirstParagh={setFirstParagh} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="sm" type="submit">{isLoading ? <Spinner /> : <>Senden</>}</Button>
      </Modal.Footer>
    </Form>
  </Modal>
}

export default EmailModal
