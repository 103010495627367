import http, { apiUrl } from './http'
import { getToken } from './auth'

export const getVideoList = () => {
  return http.get(apiUrl + 'videosource', {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const getVideo = (id) => {
  return http.get(apiUrl + 'videosource/' + id, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const getNextVideo = (id) => {
  return http.get(apiUrl + 'videosource/' + id + '/next-video', {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const uploadVideo = (data) => {
  return http.post(apiUrl + 'videosource/upload', data, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const uploadThumbnail = (id, thumbnail) => {
  return http.post(apiUrl + 'videosource/upload-thumbnail/' + id, thumbnail, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const updateVideo = (id, video) => {
  return http.post(apiUrl + 'videosource/update/' + id, video, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const deleteVideo = (id) => {
  return http.delete(apiUrl + 'videosource/delete/' + id, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const editVideoSection = (payload) => {
  return http.post(apiUrl + 'videosource/video-section/edit', payload, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const deleteVideoSection = (id, sectionTitle) => {
  return http.delete(apiUrl + 'videosource/' + id + '/video-section/' + sectionTitle, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const hideVideoSection = (payload) => {
  return http.patch(apiUrl + 'videosource/hide-video-section', payload, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const updateVideoTitle = (id, newVideoTitle) => {
  // https://inmindvideo-api-staging.herokuapp.com/api/videosource/{video id}/update/{newVideoTitle}
  return http.patch(apiUrl + 'videosource/' + id + '/update/' + newVideoTitle, {}, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const getFileChunks = (targetFile) => {
  // create array to store the buffer chunks
  let FileChunk = []
  // the file object itself that we will work with
  let file = targetFile.get('video')
  // set up other initial vars
  let MaxFileSizeMB = 10
  let BufferChunkSize = MaxFileSizeMB * (1024 * 1024)
  let ReadBuffer_Size = 1024
  let FileStreamPos = 0
  // set the initial chunk length
  let EndPos = BufferChunkSize
  let Size = file.size

  // add to the FileChunk array until we get to the end of the file
  while (FileStreamPos < Size) {
    // "slice" the file from the starting position/offset, to  the required length
    FileChunk.push(file.slice(FileStreamPos, EndPos))
    FileStreamPos = EndPos; // jump by the amount read
    EndPos = FileStreamPos + BufferChunkSize; // set next chunk length
  }
  // get total number of "files" we will be sending
  let TotalParts = FileChunk.length
  let PartCount = 0
  // loop through, pulling the first item from the array each time and sending it

  let chunks = []
  let chunk = FileChunk.shift()
  while (chunk) {
    PartCount++
    // file name convention
    let filePartName = file.name + '.part_' + PartCount + '.' + TotalParts

    // send the file
    //await uploadFileChunk(chunk, filePartName)

    chunks.push({ chunk: chunk, filePartName: filePartName })

    chunk = FileChunk.shift()
  }

  return chunks
}

export const uploadFileChunk = (chunk, filename, existingVideoId, overwriteSections) => {
  let data = new FormData()
  data.append('file', chunk, filename)

  let postUrl = apiUrl + 'videosource/upload-partitions'
  
  if(existingVideoId){
    postUrl += '/' + existingVideoId + '/' + overwriteSections
  }

  return http.post(postUrl, data, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}
