import jwtDecode from 'jwt-decode'
import http from './http'

export const login = async (username, password) => {
  const { data: user } = await http.post('login', {
    username,
    password
  })

  if (!user.accountExpired) {
    localStorage.setItem('imv-token', user.token)
    localStorage.setItem('imv-accex', user.accountExpired)
    localStorage.setItem('imv-utype', user.userType)
    localStorage.setItem('imv-playb', user.userOptions?.playbackMode)
  }

  return user
}

export const logout = () => {
  localStorage.clear()
}

export const getToken = () => {
  return localStorage.getItem('imv-token')
}

export const getHttpAuthHeaders = () => {
  return {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  }
}


/**
 * @typedef {Object} LocalStorageUser
 * @property {string} AccountExpiryDate
 * @property {string} aud
 * @property {string} email
 * @property {string} exp
 * @property {string} iss
 * @property {string} jti
 * @property {string} Roles
 * @property {string} sub Username
 */
/**
 * Returns the current user object and information (read-only)
 * @return {LocalStorageUser}
 */
export const getCurrentUser = () => {
  try {
    return jwtDecode(getToken())
  } catch (error) {
    return null
  }
}

export const isCurrentUserAdmin = () => {
  return getCurrentUser()?.Roles.toLowerCase() === 'admin'
}

export const getCurrentUserPlaybackMode = () => {
  return localStorage.getItem('imv-playb') === 'audio' ? 'audio' : 'video'
}

export const saveUserOptionAsync = (option, value) => {
  return http.post('profile/set-option', { option, value }, getHttpAuthHeaders())
}
