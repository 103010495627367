import React from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import { getValidityDaysOptions, addValidityDaysOptions } from '../../services/config';

const Settings = () => {

    const [validtyOptions, setValidityOptions] = useState([])
    const [validityOptionToAdd, setValidityOptionToAdd] = useState('');

    useEffect(() => {
        const _getValidityDaysOptions = async () => {
            const { data: _validityDaysOptions } = await getValidityDaysOptions();
            setValidityOptions(_validityDaysOptions)
        };
        _getValidityDaysOptions();
    }, [])

    const handleAddValidityOption = async () => {
        try {
            const {data: validityDays} = await addValidityDaysOptions(validityOptionToAdd)
            setValidityOptions(validityDays.value)
            setValidityOptionToAdd('')
        } catch (e) {
            console.log(e)
        }
    }

    // FOR MULTIPLE ADDITION
    // const addValidityOption = () => {
    //     let _validityOptions = validtyOptions
    //     setValidityOptions([..._validityOptions, '1'])
    // }

    // const editValidityOptions = (index, value) => {
    //     let _validityOptions = validtyOptions
    //     _validityOptions[index] = value
    //     setValidityOptions([..._validityOptions])
    // }

    // const removeValidityOption = (index) => {
    //     let _validityOptions = validtyOptions
    //     _validityOptions.splice(index, 1)
    //     setValidityOptions([..._validityOptions]);
    // }

    return (
        <section>
            <Col>
                <Row className="mb-4">
                    <Col lg="8">
                        <hr/>
                        <h5 className="mb-3">Account Validity Options</h5> 
                        {validtyOptions.length > 0 &&
                            <div className="mb-3">
                                {validtyOptions.map((option, index) => (
                                    <span>{index == 0 ? option : `, ${option}`}</span>
                                    // <React.Fragment key={`validity-option-${index}`} >
                                    //     <Row className="mb-2">
                                    //         <Col xs="3">
                                    //             <InputGroup size="sm">
                                    //                 <Form.Control value={option} type='text' onChange={(e) => editValidityOptions(index, e.target.value)} />
                                    //                 <InputGroup.Append>
                                    //                     <InputGroup.Text id="basic-addon2">{`day${option > 1 ? 's' : ''}`}</InputGroup.Text>
                                    //                 </InputGroup.Append>
                                    //             </InputGroup>
                                    //         </Col>
                                    //         {index > 1 &&
                                    //         <Col className="px-0" xs="2">
                                    //             <div className="d-flex align-items-center h-100 ">
                                    //                 <span onClick={() => removeValidityOption(index)} className="text-light hover-danger" title="remove option" style={{ cursor: 'pointer' }}><i className="fas fa-minus-circle"></i></span>
                                    //             </div>
                                    //         </Col>
                                    //         }
                                    //     </Row>
                                    // </React.Fragment>
                                ))}
                                <span> days</span>
                            </div>
                        }
                        <Row>
                            <Col md="4">
                                <InputGroup>
                                    <Form.Control placeholder="Add Days" type='text' value={validityOptionToAdd} onChange={(e) => {setValidityOptionToAdd(e.target.value)}} />
                                    <InputGroup.Append>
                                        <Button variant="primary" onClick={() => handleAddValidityOption()}>Add</Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Row>
                        {/* <Button className="mt-2 px-3 mr-2" variant="outline-primary" size="sm" onClick={() => addValidityOption()}>Add Option</Button>
                        <Button className="mt-2 px-3" variant="primary" size="sm" onClick={() => {}}>Save</Button> */}
                        
                    </Col>
                </Row>
            </Col>
        </section>
    );
};
                
export default Settings;