import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import Sidebar from '../../components/sidebar/sidebar'
import NotFound from '../../pages/404'
import Accounts from '../../pages/admin/accounts'
import Dashboard from '../../pages/admin/dashboard'
import VideoGroups from '../../pages/admin/videoGroups'
import AdminVideos from '../../pages/admin/videos'
import { getCurrentUser } from '../../services/auth'
import NavigationBar from '../nav/navigationBar'
import ProtectedRoute from '../redirect/protectedRoute'
import Settings from '../../pages/admin/settings'
import BrowserCompatibilityAlert from '../browserCompatibilityAlert'
import Footer from '../footer/footer'

const AdminHolder = () => {

    let history = useHistory()

    const user = getCurrentUser()
    if (user && user.Roles.toLowerCase() !== 'admin')
        history.push('/videos')

    return (
        <>
            <NavigationBar container="fluid" userRole="admin" />
            <Container fluid={true} style={{minHeight:800}}>
                <Row>
                    <Sidebar />
                    <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
                        <BrowserCompatibilityAlert/>
                        <Switch>
                            {/* <ProtectedRoute
                                path="/admin/dashboard"
                                render={props => <Dashboard {...props} />}
                            /> */}
                            <ProtectedRoute
                                path="/admin/videos"
                                render={props => <AdminVideos {...props} />}
                            />
                            <ProtectedRoute
                                path="/admin/video-groups"
                                render={props => <VideoGroups {...props} />}
                            />
                            <ProtectedRoute
                                path="/admin/accounts"
                                render={props => <Accounts {...props} />}
                            />
                            <ProtectedRoute
                                path="/admin/settings"
                                render={props => <Settings {...props} />}
                            />
                            <Route exact path="/admin" render={() => <Redirect to="/admin/videos" />} />
                            <Route path="/not-found" component={NotFound} />
                            <Redirect to="/not-found" />
                        </Switch>
                    </main>
                </Row>
            </Container>
            <Footer />
        </>
    )
}

export default AdminHolder