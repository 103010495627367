import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Modal } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import thumbnail from '../assets/images/thumbnail.png'
import { getVideoList } from '../services/videos'
import { getCurrentUserVideoGroups, getVideoGroupList } from '../services/videoGroup'
import { getCurrentUser, isCurrentUserAdmin } from '../services/auth'

const Videos = () => {
  const [userVideoGroups, setUserVideoGroups] = useState([])
  const [allVideoGroups, setAllVideoGroups] = useState([])
  const [ungroupedVideos, setUngroupedVides] = useState([])
  const [groupInfo, setGroupInfo] = useState(null)
  const [groupInfoModal, setGroupInfoModal] = useState(false)

  useEffect(() => {
    const _getAllVideoGroups = async (videos) => {
      let { data: _videoGroups } = await getVideoGroupList()
      _videoGroups = _videoGroups.sort((a, b) => new Date(a.orderRank) - new Date(b.orderRank))

      setAllVideoGroups(_videoGroups)
      getUngroupedVideos(videos, _videoGroups);
    }

    const _getVideos = async () => {
      const { data: videos } = await getVideoList()
      await _getAllVideoGroups(videos)
    }
    _getVideos()

    const _getUserVideoGroups = async () => {
      const { data: _userVideoGroups } = await getCurrentUserVideoGroups()
      setUserVideoGroups(_userVideoGroups)
    }
    _getUserVideoGroups()
  }, [])


  const getUngroupedVideos = (videos, _videoGroups) => {
    const _handleOtherVideos = (video) => {
      return !_videoGroups.some(a => a.videos.some(b => b.id == video.id))
    }

    let tempUngroupedVideos = videos && videos.filter(video => _handleOtherVideos(video))
    setUngroupedVides(tempUngroupedVideos)
  }

  const handleGroupInfo = (group) => {
    if (isMyGroup(group) || isCurrentUserAdmin()) {
      setGroupInfo(group)
      setGroupInfoModal(true)
    }
  }

  const isMyGroup = (group) => {
    return userVideoGroups.some(x => x.id === group.id)
  }


  return <>
    <Modal
      show={groupInfoModal}
      onHide={() => setGroupInfoModal(false)}
      aria-labelledby="group-info-modal"
      size="xl"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="group-info-modal">
          {groupInfo && groupInfo.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {groupInfo && groupInfo.videos.map((video, index) => (
            <Col key={`groupvideo_${index}`} lg="4" className="mb-4">
              <LinkContainer to={`/video/${video.id}`} style={{ cursor: 'pointer' }}>

                <Card>
                  <div style={{ height: '200px', overflow: 'hidden' }}>
                    <Card.Img className="w-100" variant="top" src={video.thumbnail ? video.thumbnail : thumbnail} />
                  </div>
                  <Card.Body>
                    <Card.Title>{video.name}</Card.Title>
                    {video.description}
                  </Card.Body>
                </Card>
              </LinkContainer>
            </Col>
          ))}
        </Row>
      </Modal.Body>
    </Modal>

    {isCurrentUserAdmin() ?
      <>
        <h5>Übersicht</h5>
        <Row>{allVideoGroups.map((group, index) => (
          <Col key={`group-${index}`} lg="3" className="mb-4">
            <Card onClick={() => handleGroupInfo(group)} style={{ 'cursor': 'pointer' }}>
              <Card.Body>
                <Card.Title>{group.name}</Card.Title>
                <p className="mb-0 text-muted">
                  <i className="fas fa-video"></i> {group.videos.length} Videos
                </p>
              </Card.Body>
            </Card>
          </Col>
        ))}
        </Row>
      </>
      :
      <>
        <h5>Übersicht</h5>
        <Row>{allVideoGroups.map((group, index) => (
          <Col key={`group-${index}`} lg="3" className="mb-4">
            <Card onClick={() => handleGroupInfo(group)} style={{ 'cursor': 'pointer', 'opacity': isMyGroup(group) ? '1' : '0.5' }}>
              <Card.Body>
                <Card.Title>{group.name}</Card.Title>

                <p className="mb-0 text-muted">
                  <i className="fas fa-video"></i> {group.videos.length} Videos
                </p>
                {!isMyGroup(group) &&
                  <small style={{ 'position': 'absolute', 'right': '15px', 'bottom': '10px' }} className="text-danger">Kein Zugriff</small>
                }
              </Card.Body>
            </Card>
          </Col>
        ))}
        </Row>
      </>
    }

    {isCurrentUserAdmin() &&
      <>
        {(ungroupedVideos && ungroupedVideos.length > 0) && <h5>weitere Videos</h5>}
        <Row>
          {
            ungroupedVideos && ungroupedVideos.map((video, index) => (
              <Col key={`video_${index}`} lg="4" className="mb-4">
                <LinkContainer key={`video_${index}`} to={`/video/${video.id}`} style={{ cursor: 'pointer' }}>

                  <Card>
                    <div style={{ height: '200px', overflow: 'hidden' }}>
                      <Card.Img className="w-100" variant="top" src={video.thumbnail ? video.thumbnail : thumbnail} />
                    </div>
                    <Card.Body className="text-ellipsis">
                      <Card.Title>{video.title}</Card.Title>
                      {video.description}
                    </Card.Body>
                  </Card>
                </LinkContainer>
              </Col>
            ))
          }
        </Row>
      </>
    }
  </>
}

export default Videos
