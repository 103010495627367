import React, { useState } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'
import { getCurrentUserPlaybackMode, saveUserOptionAsync } from '../../services/auth'
import Spinner from '../spinner'


const PlaybackModeSwitcher = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [loadingError, setLoadingError] = useState('')
  const [playbackMode, setPlaybackMode] = useState(getCurrentUserPlaybackMode())

  const _setPlaybackMode = (mode) => {
    setPlaybackMode(mode)
    setIsLoading(true)
    saveUserOptionAsync('playbackMode', mode)
      .then(() => {
        localStorage.setItem('imv-playb', mode)
        window.location.reload()
      })
      .catch((ex) => setLoadingError(ex.message || ex))
  }

  return <>
    {loadingError && <Alert variant="danger">Es ist ein Fehler aufgetreten! {loadingError}</Alert>}
    {isLoading && <Spinner />}
    <Form.Group>
      <Row>
        <Col>
          <Form.Label>Abspielmodus einstellen</Form.Label>
          <Form.Control as="select" value={playbackMode}
            onChange={(event) => _setPlaybackMode(event.target.value)}>
            <option value="video">Video</option>
            <option value="audio">Audio</option>
          </Form.Control>
        </Col>
        <Col>
        </Col>
      </Row>
    </Form.Group>
  </>
}

export default PlaybackModeSwitcher
