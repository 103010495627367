import { differenceInCalendarDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { Alert, Badge, Button, Card, Col, Form, FormControl, InputGroup, Modal, Row, Table } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../../components/spinner'
import { getValidityDaysOptions } from '../../services/config'
import { addDaysToExpiration, createUser, getUsers, removeUser, searchUsersByName } from '../../services/users'
import { addUserToGroup, deleteUserToGroup, getUserVideoGroups, getVideoGroupList } from '../../services/videoGroup'
import { expired } from '../../utils/account'
import { removeFromArray } from '../../utils/array'
import { randomPassword } from '../../utils/randomPassword'
import EmailModal from '../admin/emailModal'

// TODO: class too long, consider refactoring
const Accounts = () => {
  const [accountsState, setAccountsState] = useState([])
  const [accountToAdd, setAccountToAdd] = useState({})
  const [videoGroups, setVideoGroups] = useState([])
  const [videoGroupToAdd, setVideoGroupToAdd] = useState([])
  const [videoGroupsToAdd, setVideoGroupsToAdd] = useState([]) // reserve for multiple vid groups to add later
  const [newUserVideoGroupsToAdd, setNewUserVideoGroupsToAdd] = useState([])
  const [newUserUpdatedVideoGroupsToAdd, setNewUserUpdatedVideoGroupsToAdd] = useState([])
  const [expirationDaysToAdd, setExpirationDaysToAdd] = useState(90)
  const [selectedAccount, setSelectedAccount] = useState({})
  const [userVideoGroups, setUserVideoGroups] = useState([])
  const [userVideoGroupsFetched, setUserVideoGroupsFetched] = useState(false)
  const [validated, setValidated] = useState(false)
  const [accountOptionsModal, setAccountOptionsModal] = useState(false)
  const [videoGroupsModal, setVideoGroupsModal] = useState(false)
  const [hideExpiredAccounts, setHideExpiredAccounts] = useState(localStorage.getItem('imv-hideexpacc') == '1' ? true : false)
  const [defaultPassword, setDefaultPassword] = useState(randomPassword(10));
  const [loadingError, setLoadingError] = useState('')
  const [searchStr, setSearchStr] = useState('')
  const [validityOptions, setValidityOptions] = useState([])
  const [validityOptionsSelected, setValidityOptionsSelected] = useState(90)
  const [userTypeSelected, setUserTypeSelected] = useState('efc')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCreateAccount, setIsLoadingCreateAccount] = useState(false)

  const [username, setUsername] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [title, setTitle] = useState('')

  const [displayEmailBodyModal, setDisplayEmailBodyModal] = useState(false)
  const [receiverFullName, setReceiverFullName] = useState('')
  const [receiverEmail, setReceiverEmail] = useState('')
  const [userType, setUserType] = useState('')
  const [userDuration, setUserDuration] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [firstParagh, setFirstParagh] = useState('')


  const _getUsers = async () => {
    const { data: _users } = !searchStr ? await getUsers() : await searchUsersByName(searchStr)
    const users = _users.filter(user => !user.roles || !user.roles.includes('Admin'))
      .map(user => {
        user.passVisible = false
        return user
      })

    setAccountsState(users)
  }

  useEffect(() => {
    const _getVideoGroups = async () => {
      const { data: _videoGroups } = await getVideoGroupList()
      setVideoGroups(_videoGroups)
    }

    const _getValidityOptions = async () => {
      let { data: _validityDaysOptions } = await getValidityDaysOptions()
      _validityDaysOptions.sort(function (a, b) { return a - b })
      setValidityOptions(_validityDaysOptions)
    }

    setIsLoading(true)
    Promise.all([_getUsers(), _getVideoGroups(), _getValidityOptions()])
      .then(() => setLoadingError(''))
      .catch((ex) => setLoadingError(ex.message || ex))
      .finally(() => setIsLoading(false))
  }, [])


  const handleSearch = async () => {
    setIsLoading(true)
    _getUsers()
      .then(() => setLoadingError(''))
      .catch((ex) => setLoadingError(ex.message || ex))
      .finally(() => setIsLoading(false))
  }

  const togglePassword = (index, passVisible) => {
    let tempAccountsState = [...accountsState]
    tempAccountsState[index].passVisible = !passVisible
    setAccountsState(tempAccountsState)
  }


  const addAccount = (account) => {
    const _account = account ? account : accountToAdd
    setAccountsState([...accountsState, _account])
  }

  const removeAccount = (index) => {
    var tempAccountsState = accountsState
    tempAccountsState.splice(index, 1);
    setAccountsState([...tempAccountsState])
  }

  const confirmRemoveAccount = (id, index) => {
    confirmAlert({
      title: 'Delete Account',
      message: `Are you sure you want to delete *${accountsState[index].username}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              setIsLoading(true)

              await removeUser(id)
              removeAccount(index)
            } catch (e) {
              console.error(e)
            }
            setIsLoading(false)
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    })
  }


  useEffect(() => {
    updateUsername()
  }, [title, lastName, firstName])


  const updateUsername = () => {
    let newUsername = ""

    if (firstName) {
      newUsername += firstName
    }
    if (lastName) {
      newUsername += " " + lastName
    }
    if (title && title != 'None') {
      newUsername = title + " " + newUsername
    }

    handleChange('username', newUsername)
    setUsername(newUsername)
  }

  const handleChange = (field, value) => {
    setValues(field, value)
    let tempAccountTodAdd = accountToAdd
    tempAccountTodAdd[field] = value
    tempAccountTodAdd['passVisible'] = false
    setAccountToAdd(tempAccountTodAdd)
  }

  const setValues = (field, value) => {
    if (field === 'nDaysBeforeExpiration') {
      setValidityOptionsSelected(value)
    } else if (field === 'userType') {
      setUserTypeSelected(value)
    } else if (field === 'lastName') {
      setLastName(value)
    } else if (field === 'firstName') {
      setFirstName(value)
    } else if (field === 'title') {
      setTitle(value)
    } else if (field === 'username') {
      setUsername(value)
    }
  }

  const createAccount = async () => {
    setIsLoadingCreateAccount(true)
    const newAccount = {
      'firstName': accountToAdd.firstName,
      'lastName': accountToAdd.lastName,
      'username': accountToAdd.username,
      'password': accountToAdd.password ?? defaultPassword,
      'email': accountToAdd.email,
      'userType': accountToAdd.userType ?? userTypeSelected,
      'genderDesignation': accountToAdd.genderDesignation ?? 'herr',
      'nDaysBeforeExpiration': accountToAdd.nDaysBeforeExpiration ? parseInt(accountToAdd.nDaysBeforeExpiration) : validityOptionsSelected,
      'videoGroupIds': newUserVideoGroupsToAdd,
      'title': accountToAdd.title ?? 'None'
    }

    try {
      const { data: accountCreated } = await createUser(newAccount)

      addAccount(accountCreated)
      setDefaultPassword(randomPassword(10))
      setNewUserVideoGroupsToAdd([])
      toast.success('Account erstellt');
      document.querySelector('#create-account-form').reset()

      handleChange('title', 'None')
      handleChange('password', defaultPassword)
      handleChange('userType', 'efc')
      handleChange('genderDesignation', 'herr')
      handleChange('nDaysBeforeExpiration', '90')
      handleChange('username', '')
      setValidityOptionsSelected('90')
      setUserTypeSelected('efc')
      setUsername('')
      setLastName('')
      setFirstName('')
      setTitle('')
    } catch (e) {
      let err = 'Fehler aufgetreten: Bitte versuchen Sie es erneut.'

      if(e.response && e.response.data && e.response.data.message && (typeof e.response.data.message === 'string' || e.response.data.message instanceof String))
        err =  e.response.data.message

      setLoadingError(err)
      toast.error(err)
    }
    setIsLoadingCreateAccount(false)
  }


  const handleSubmit = (event) => {
    const form = event.currentTarget

    event.preventDefault()
    event.stopPropagation()
    setLoadingError('')
    if (!form.checkValidity()) {
      setValidated(true)
      return false
    }

    if (!newUserVideoGroupsToAdd.length) {
      setLoadingError('Please select group(s) to proceed')
      return false
    }

    createAccount()
  }

  const handleVideoGoupToAdd = (id) => {
    videoGroupToAdd === id ? setVideoGroupToAdd('') : setVideoGroupToAdd(id)
  }

  // reserve for multiple vid groups to add later
  const handleVideoGroupsToAdd = (id) => {
    if (videoGroupsToAdd.some(vidId => vidId === id)) {
      let _videoGroupsToAdd = videoGroupsToAdd
      _videoGroupsToAdd = removeFromArray(_videoGroupsToAdd, id)
      setVideoGroupsToAdd([..._videoGroupsToAdd])
    } else {
      let _videoGroupsToAdd = videoGroupsToAdd
      setVideoGroupsToAdd([..._videoGroupsToAdd, id])
    }
  }

  const handleNewUserVideoGroupsToAdd = (id) => {
    if (newUserVideoGroupsToAdd.some(groupId => groupId === id)) {
      let _newUserVideoGroupsToAdd = newUserVideoGroupsToAdd
      _newUserVideoGroupsToAdd = removeFromArray(_newUserVideoGroupsToAdd, id)
      setNewUserVideoGroupsToAdd([..._newUserVideoGroupsToAdd])
    } else {
      let _newUserVideoGroupsToAdd = newUserVideoGroupsToAdd
      setNewUserVideoGroupsToAdd([..._newUserVideoGroupsToAdd, id])
    }
  }

  const handleNewUserUpdatedVideoGroupsToAdd = (id) => {
    if (newUserUpdatedVideoGroupsToAdd.some(groupId => groupId === id)) {
      let _newUserUpdatedVideoGroupsToAdd = newUserUpdatedVideoGroupsToAdd
      _newUserUpdatedVideoGroupsToAdd = removeFromArray(_newUserUpdatedVideoGroupsToAdd, id)
      setNewUserUpdatedVideoGroupsToAdd([..._newUserUpdatedVideoGroupsToAdd])
    } else {
      let _newUserUpdatedVideoGroupsToAdd = newUserUpdatedVideoGroupsToAdd
      setNewUserUpdatedVideoGroupsToAdd([..._newUserUpdatedVideoGroupsToAdd, id])
    }
  }

  const openAddToGroupModal = () => {
    setVideoGroupsModal(true)
    setNewUserUpdatedVideoGroupsToAdd([])

  }

  const handleGetUserVideoGroups = async (id) => {
    const { data: _videoGroups } = await getUserVideoGroups(id)
    setUserVideoGroups(_videoGroups)
    setUserVideoGroupsFetched(true)
  }

  const handleAccountOptions = (id) => {
    setVideoGroupToAdd('')
    setSelectedAccount(accountsState.find(account => account.id == id))
    setUserVideoGroups([])
    setUserVideoGroupsFetched(false)
    handleGetUserVideoGroups(id)

    setAccountOptionsModal(true)
  }

  const addVideoGroupToUser = async () => {
    newUserUpdatedVideoGroupsToAdd.forEach(async (val) => {
      try {
        await addUserToGroup(val, selectedAccount.id)
        await handleGetUserVideoGroups(selectedAccount.id)
        setVideoGroupsModal(false)
      } catch (e) {
        console.error(e)
      }
    })
  }

  const handleDeleteVideoGroupToUser = async (groupId) => {
    try {
      await deleteUserToGroup(groupId, selectedAccount.id)
      await handleGetUserVideoGroups(selectedAccount.id)
    } catch (e) {
      console.error(e)
    }
  }

  const handleAddExpirationDays = async () => {
    try {
      const { data: result } = await addDaysToExpiration(selectedAccount.id, expirationDaysToAdd)
      let _selectedAccount = selectedAccount
      _selectedAccount.expiryDate = result.expiryDate
      _selectedAccount.nDaysBeforeExpiration = result.nDaysBeforeExpiration
      setSelectedAccount({ ..._selectedAccount })
      setAccountOptionsModal(false)
    } catch (e) {
      console.error(e)
    }
  }


  const handleHideExpiredAccounts = () => {
    hideExpiredAccounts ? localStorage.removeItem('imv-hideexpacc') : localStorage.setItem('imv-hideexpacc', '1')
    setHideExpiredAccounts(!hideExpiredAccounts)
  }

  const handleRemainingDays = (expiryDate) => {
    const difference = differenceInCalendarDays(new Date(expiryDate), new Date())
    const remainingDays = difference < 0 ? 0 : difference
    return remainingDays > 1 ? `${remainingDays} days` : `${remainingDays} day`
  }

  const accountInfoToEmail = (account) => {
    strAccountInfo(account)
    setDisplayEmailBodyModal(true)
  }

  const strAccountInfo = (account) => {
    let title = account.title && account.title !== 'None' ? ' ' + account.title + ' ' : ' '

    const fullname = title + account.lastName

    let tempFirstParagh = ('Sehr geehrte' + (account.genderDesignation === 'Herr' ? 'r' : '')
      + ' {genderDesignation}{fullName},\n\nim Folgenden finden Sie wie besprochen den Zugangslink zu meinem Online-Portal.')
      .replace("{genderDesignation}", account.genderDesignation)
      .replace("{fullName}", fullname)

    setUserType(account.userType)
    setUsername(account.username)
    setUserPassword(account.password)

    if(!account.expirationCheckingStarted){
      setUserDuration(account.nDaysBeforeExpiration.toString())
    }
    else{
      const daysLeft = handleRemainingDays(account.expiryDate);
      setUserDuration(daysLeft)
    }
    setReceiverEmail(account.email)
    setReceiverFullName(fullname)
    setFirstParagh(tempFirstParagh)
  }


  return <>
    {loadingError && <Alert variant="danger">Es ist ein Fehler aufgetreten! {loadingError}</Alert>}

    {/* Add Video Group */}
    <Modal
      show={videoGroupsModal}
      onHide={() => setVideoGroupsModal(false)}
      aria-labelledby="add-videogroups-modal"
      size="lg"
      backdropClassName="level-2"
      centered>
      <Form id="add-videogroups-form" noValidate>
        <Modal.Header closeButton>
          <Modal.Title>
            Select Video Groups To Add
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>{videoGroups.filter(a => !(userVideoGroups.some(b => b.id == a.id))).map((videoGroup, index) => (
            <Col key={`videogroup-${index}`} className="mb-3" md="6" lg="4">
              <Card
                text={newUserUpdatedVideoGroupsToAdd.some(group => group == videoGroup.id) ? "white" : "dark"}
                bg={newUserUpdatedVideoGroupsToAdd.some(group => group == videoGroup.id) ? "primary" : "white"} className="h-100 " style={{ 'cursor': 'pointer' }}
                onClick={() => handleNewUserUpdatedVideoGroupsToAdd(videoGroup.id)}>
                <div className="p-2">
                  <p className="mb-0">{videoGroup.name}</p>
                </div>
              </Card>
            </Col>
          ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setVideoGroupsModal(false)}>Close</Button>
          <Button type="button" onClick={() => addVideoGroupToUser()} >Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>

    {/* Account Video Groups Modal */}
    <Modal
      show={accountOptionsModal}
      onHide={() => setAccountOptionsModal(false)}
      aria-labelledby="account-options-modal"
      centered>
      <Form id="account-options-form" noValidate>
        <Modal.Header closeButton>
          <Modal.Title>
            Account Options for {selectedAccount.username}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <div className="account-video-groups mb-3">
                {userVideoGroups.length == 0 && userVideoGroupsFetched ?
                  <p>This user has no video groups</p>
                  :
                  <>
                    {userVideoGroups.map((userVideoGroup, index) => (
                      <p key={`user-videogroup-${index}`} className="mb-0 remove-icon-parent">
                        <Link className="text-dark" to={`#`}>{userVideoGroup.name}</Link><i onClick={() => handleDeleteVideoGroupToUser(userVideoGroup.id)} title="remove" className="remove-icon fas fa-times-circle text-muted cursor-pointer ml-1"></i>
                      </p>
                    ))}
                  </>
                }
              </div>
              <div>
                <Button size="sm" onClick={() => openAddToGroupModal()}>Add Video Group</Button>
              </div>
            </Col>
            <Col>
              <Form.Group>
                <Row>
                  <Col>
                    <p className="mb-0">Expiry Date: &nbsp;
                      {
                        selectedAccount.expiryDate == '0001-01-01T00:00:00' || selectedAccount.expiryDate == '0001-01-01T00:00:00Z' ?
                          <span className="text-muted">No Expiration</span>
                          :
                          <>
                            <span className="text-muted mr-2">
                              {new Date(selectedAccount.expiryDate).toLocaleDateString('de-DE', {
                                day: 'numeric',
                                month: 'short',
                                year: (new Date(selectedAccount.expiryDate).getFullYear() == new Date().getFullYear()) ? undefined : 'numeric',
                              })}</span>
                            {expired(selectedAccount.expiryDate) &&
                              <Badge variant="danger">Expired</Badge>
                            }
                          </>
                      }
                    </p>
                    <p>Days Left: <span className="text-muted">{handleRemainingDays(selectedAccount.expiryDate)}</span></p>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Add Days</Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col sm="8" className="pr-0">
                    <Form.Control size="sm" as="select" className="exclude-validation" value={expirationDaysToAdd} onChange={(event) => setExpirationDaysToAdd(event.target.value)}>
                      {validityOptions.map((option, index) => (
                        <option key={`validityoption-${index}`} value={option}>{option} Day{option > 1 ? 's' : ''}</option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col>
                    <Button size="sm" onClick={() => handleAddExpirationDays()}>Add</Button>
                  </Col>
                </Row>
              </Form.Group>

            </Col>
          </Row>
        </Modal.Body>
      </Form>
    </Modal>


    {/* Account info email Modal */}
    {
      <EmailModal setDisplayEmailBodyModal={setDisplayEmailBodyModal}
        displayEmailBodyModal={displayEmailBodyModal}
        receiverEmail={receiverEmail}
        receiverFullName={receiverFullName}
        userType={userType}
        userPassword={userPassword}
        username={username}
        userDuration={userDuration}
        setFirstParagh={setFirstParagh}
        firstParagh={firstParagh} />
    }


    <section>
      <Col>
        <Row className="mb-4">
          <Col lg="8" md="12" sm="12">
            <hr />
            <h5 className="mb-3">Create an Account</h5>
            <Form noValidate validated={validated} id="create-account-form" onSubmit={handleSubmit} autoComplete="new-password">
              <Row>
                <Col lg="6">
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email" autoComplete="none"
                      onChange={(event) => handleChange('email', event.target.value)}
                      required />
                    <Form.Control.Feedback type="invalid">
                      Email is invalid
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" placeholder="Username" defaultValue={username} value={username}
                      onChange={(event) => handleChange('username', event.target.value)}
                      required disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      Username is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="text" placeholder="Password"
                      defaultValue={defaultPassword}
                      onChange={(event) => handleChange('password', event.target.value)}
                      required />
                    <Form.Control.Feedback type="invalid">
                      Password is required
                    </Form.Control.Feedback>
                    <Button variant="link" size="sm" className="p-0 text-muted"
                      onClick={() => handleChange('password', setDefaultPassword(randomPassword(10)))}>
                      Regenerate password</Button>
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>User Type</Form.Label>
                        <Form.Control as="select" value={userTypeSelected} className="exclude-validation"
                          onChange={(event) => handleChange('userType', event.target.value)}>
                          <option value="efc">EFC</option>
                          <option value="pt">PT</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Account Validity</Form.Label>
                        <Form.Control as="select" className="exclude-validation" value={validityOptionsSelected}
                          onChange={(event) => handleChange('nDaysBeforeExpiration', event.target.value)}>
                          {validityOptions.map((option, index) => (
                            <option key={`validityoption-${index}`} value={option}>{option} Day{option > 1 ? 's' : ''}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Gender</Form.Label>
                        <Form.Control as="select" className="exclude-validation"
                          onChange={(event) => handleChange('genderDesignation', event.target.value)}>
                          <option value="herr">Herr</option>
                          <option value="frau">Frau</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Form.Group controlId="firstname">
                    <Form.Label>Firstname</Form.Label>
                    <Form.Control type="text" placeholder="Firstname" autoComplete="none"
                      onChange={(event) => handleChange('firstName', event.target.value)}
                      required />
                    <Form.Control.Feedback type="invalid">
                      Firstname is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="lastname">
                    <Form.Label>Lastname</Form.Label>
                    <Form.Control type="text" placeholder="Lastname" autoComplete="none"
                      onChange={(event) => handleChange('lastName', event.target.value)}
                      required />
                    <Form.Control.Feedback type="invalid">
                      Lastname is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control as="select" className="exclude-validation"
                      onChange={(event) => handleChange('title', event.target.value)}>
                      <option value="None"></option>
                      <option value="Prof.">Prof.</option>
                      <option value="Dr.">Dr.</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Label>Select Group(s)</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    {videoGroups.map((videoGroup, index) => (
                      <Col key={`usercreate-videogroup-${index}`} className="mb-3" md="6" lg="4">
                        <Card
                          text={newUserVideoGroupsToAdd.some(group => group == videoGroup.id) ? "white" : "dark"}
                          bg={newUserVideoGroupsToAdd.some(group => group == videoGroup.id) ? "primary" : "white"}
                          className="h-100 " style={{ 'cursor': 'pointer' }}
                          onClick={() => handleNewUserVideoGroupsToAdd(videoGroup.id)}>
                          <div className="p-2">
                            <p className="mb-0">{videoGroup.name}</p>
                          </div>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Button variant="primary" size="sm" type="submit">
                Create
              </Button>
              {isLoadingCreateAccount && <Spinner />}
            </Form>
          </Col>
        </Row>


        <Row>
          <Col>
            {loadingError && <Alert variant="danger">Es ist ein Fehler aufgetreten! {loadingError}</Alert>}
            <hr />
            <Row>
              <Col>
                <h5 className="mb-3">Accounts</h5>
                {isLoading && <Spinner />}
              </Col>
              <Col className="text-right">
                <div className="form-inline justify-content-end">
                  <Form.Group controlId="hideExpired" className="mr-3">
                    <Form.Check onChange={() => handleHideExpiredAccounts()} type="checkbox" label="Hide Expired" checked={hideExpiredAccounts} />
                  </Form.Group>
                  <InputGroup className="mb-3">
                    <FormControl placeholder="Search account name" onChange={(event) => setSearchStr(event.target.value)} />
                    <InputGroup.Append>
                      <Button variant="outline-secondary" onClick={() => handleSearch()}>Search</Button>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </Col>
            </Row>

            <div style={{ overflowX: 'auto' }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Username</th>
                    <th>Password</th>
                    <th>Expiry Date</th>
                    <th>Days Left</th>
                    <th>Validity Days</th>
                    <th>User Type</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {accountsState.map((account, index) => (
                    <React.Fragment key={"account_" + index}>
                      {((!expired(account.expiryDate) && hideExpiredAccounts) || (!hideExpiredAccounts)) &&
                        <tr>
                          <td>{index + 1}</td>
                          <td><i className="fa fa-ellipsis-v cursor-pointer" onClick={() => handleAccountOptions(account.id)}></i></td>
                          <td>{account.firstName} {account.lastName}</td>
                          <td>{account.email}</td>
                          <td>{account.username}</td>
                          <td>
                            <InputGroup className="pass-toggle">
                              <FormControl
                                type={account.passVisible ? "text" : "password"}
                                value={account.password}
                                disabled
                                size="sm"
                              />
                              <InputGroup.Append>
                                <InputGroup.Text onClick={() => togglePassword(index, account.passVisible)}>
                                  {account.passVisible ? "Hide" : "Show"}
                                </InputGroup.Text>
                              </InputGroup.Append>
                            </InputGroup>
                          </td>
                          <td>
                            {
                              account.expiryDate == '0001-01-01T00:00:00' || account.expiryDate == '0001-01-01T00:00:00Z' ?
                                <span className="text-muted">No Expiration</span>
                                :
                                <>
                                  <span className="text-muted mr-2">
                                    {new Date(account.expiryDate).toLocaleDateString('de-DE', {
                                      day: 'numeric',
                                      month: 'short',
                                      year: (new Date(account.expiryDate).getFullYear() == new Date().getFullYear()) ? undefined : 'numeric',
                                    })}</span>
                                  {expired(account.expiryDate) &&
                                    <Badge variant="danger">Expired</Badge>
                                  }
                                </>
                            }
                          </td>
                          <td className="text-muted">{handleRemainingDays(account.expiryDate)}</td>
                          <td className="text-muted">{account.nDaysBeforeExpiration}</td>
                          <td>{account.userType !== 'NONE' && <span>{account.userType}</span>}</td>
                          <td className="text-center">
                            <button className="btn pl-0"><i className="fa fa-envelope" onClick={() => accountInfoToEmail(account)}></i></button>
                            <span className="cursor-pointer" onClick={() => confirmRemoveAccount(account.id, index)}><i className="fas fa-times"></i></span>
                          </td>
                        </tr>
                      }
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Col>
    </section>
  </>
}

export default Accounts
