import React from 'react';

const NotFound = () => {
    return (
        <div className="p-5 text-center">
            <h1>Error 404</h1>
            <p>page not found.</p>
        </div>
    );
};

export default NotFound;