import React from 'react'
import '../../assets/css/dashboard.css'
import '../sidebar/sidebar.css'

const Sidebar = () => {
  return <>
    <div bg="light" className="col-md-3 col-lg-2 d-none d-md-block sidebar">
      <div className="sidebar-sticky">
        <section className="px-2">
          <div className="d-flex py-2 px-3">
            <h3>Admin Menu</h3>
          </div>

          <ul className="nav flex-column">
            <li><a href="/admin/videos" className="nav-link"><i className="fas fa-tachometer-alt text-muted mr-2"></i> Videos</a></li>
            <li><a href="/admin/video-groups" className="nav-link"><i className="fas fa-tachometer-alt text-muted mr-2"></i> Übersicht</a></li>
            <li><a href="/admin/accounts" className="nav-link"><i className="fas fa-tachometer-alt text-muted mr-2"></i> Accounts</a></li>
            <li><a href="/admin/settings" className="nav-link"><i className="fas fa-tachometer-alt text-muted mr-2"></i> Settings</a></li>
          </ul>
        </section>
      </div>
    </div>
  </>
}

export default Sidebar
