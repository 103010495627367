export const IS_IOS = !window.MSStream && /iPad|iPhone|iPod/.test(navigator.userAgent)

// ipadOS 13+
export const IS_IPADOS = window.AuthenticatorAssertionResponse === undefined
  && window.AuthenticatorAttestationResponse === undefined
  && window.AuthenticatorResponse === undefined
  && window.Credential === undefined
  && window.CredentialsContainer === undefined
  && window.DeviceMotionEvent !== undefined
  && window.DeviceOrientationEvent !== undefined
  && navigator.maxTouchPoints === 5
  && navigator.plugins.length === 0
  && navigator.platform !== 'iPhone'


export const IS_IPAD = IS_IOS && /iPad/.test(navigator.userAgent) || IS_IPADOS
export const IS_IPHONE = IS_IOS && /iPhone/.test(navigator.userAgent)
export const IS_IPOD = IS_IOS && /iPod/.test(navigator.userAgent)


function getiOSVersion() {
  if (!IS_IOS) { return false }
  if (IS_IPADOS) { return 'iPadOS' } // there is literally no way to figure out if it is iPadOS

  const regexTest = /(?:iPad|iPhone|iPod) OS ?(\d+)(?:_\d+)*/.exec(navigator.userAgent)
  if (regexTest) { return 'iOS ' + regexTest[1].replace(/_/g, '.') }
  if (window.indexedDB) { return 'iOS 8' }
  if (window.SpeechSynthesisUtterance) { return 'iOS 7' }
  if (window.webkitAudioContext) { return 'iOS 6' }
  if (window.matchMedia) { return 'iOS 5' }
  if (window.history && 'pushState' in window.history) { return 'iOS 4' }
  return 'iOS 3 or earlier'
}
export const IOS_VERSION = getiOSVersion()
