import ProgressBar from 'react-bootstrap/ProgressBar';
import React from 'react';
import Spinner from '../../components/spinner'
import { Col, Row, Button, Modal, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { deleteVideo, getVideoList, getFileChunks, updateVideo, updateVideoTitle, uploadFileChunk, uploadVideo } from '../../services/videos';
import { getCurrentUser } from '../../services/auth';
import { useEffect } from 'react';
import { useState } from 'react';

const VideosAdmin = () => {
  const [videos, setVideos] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [vidSections, setVidSections] = useState([])
  const [isUploading, setIsUploading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [videoToAdd, setVideoToAdd] = useState({})
  const [videoFile, setVideoFile] = useState(null)

  const [errors, setErrors] = useState(null)
  const [modalError, setModalError] = useState('')

  const [progress, setProgress] = useState(0);

  /* #region Video Editing States */
  const [videoEditModal, setVideoEditModal] = useState(false)
  const [oldVideoTitle, setOldVideoTitle] = useState('')
  const [newVideoTitle, setNewVideoTitle] = useState('')
  const [idOfVideoToEdit, setIdOfVideoToEdit] = useState('')

  const [newVideoFile, setNewVideoFile] = useState('')
  const [editVideoOverrideVidSections, setEditVideoOverrideVidSections] = useState(false)
  /* #endregion */

  const user = getCurrentUser()

  const isAdmin = () => {
    return user.Roles.toLowerCase() == 'admin'
  }

  useEffect(() => {

    const getData = async () => {
      try {
        setIsLoading(true)
        const { data: _videos } = await getVideoList()
        setVideos(_videos)
        setIsLoading(false)
      } catch (e) {
        console.error(e)
      }
    }
    
    getData();
  }, [])

  const addVideo = (video) => {
    let _videos = videos
    setVideos([..._videos, video])
  }

  const handleEditVideoTitleModal = (title, id) => {
    setOldVideoTitle(title)
    setIdOfVideoToEdit(id)
    setVideoEditModal(true)
  }

  const editVideo = async () => {
    if (!isAdmin()) {
      return
    }

    try {
      let videoTitleComparison = (newVideoTitle ? true : false) && (oldVideoTitle.toLowerCase() != newVideoTitle.toLowerCase())
      let videoComparison = newVideoFile ? true : false

      if (videoComparison || videoTitleComparison) {
        if (videoTitleComparison) {
          const { data } = await updateVideoTitle(idOfVideoToEdit, newVideoTitle)
          setOldVideoTitle('')
          setIdOfVideoToEdit('')
        }
        if (videoComparison) {
          await chunkUploading(newVideoFile, idOfVideoToEdit, editVideoOverrideVidSections)
          setNewVideoFile('')
          setEditVideoOverrideVidSections(false)
        }
        setVideoEditModal(false)

        setTimeout(window.location.reload(), 3000)
      }


      setVideoEditModal(false)
    } catch (e) {
      console.log(e)
    }

  }

  const handleVideoToAdd = (field, value) => {
    let tempVideoToAdd = videoToAdd
    tempVideoToAdd[field] = value
    setVideoToAdd(tempVideoToAdd)
  }

  const setVideo = (event) => {
    let file = event.target.files[0]
    setVideoFile(file)
  }

  const setNewVideo = (event) => {
    let file = event.target.files[0]
    setNewVideoFile(file)
  }

  const handleUpload = async () => {
    setIsUploading(true)

    try {

      const videoUploaded = await chunkUploading(videoFile);

      //const {data: videoUploaded} = await uploadVideo(formData);
      handleVideoToAdd('url', videoUploaded.url)
      await saveVideo(videoUploaded.id)

    } catch (e) {
      console.log(e)
      setModalError('Error Occured: Make sure you select right video file.')
    }

    setProgress(0);
    setIsUploading(false)
    console.log(errors)
  }

  const chunkUploading = async (fileToUpload, existingVideoId, overwriteSections) => {
    let videoUploaded = {};

    let formData = new FormData();
    formData.append('video', fileToUpload);

    //---chunks uploading--
    var chunks = getFileChunks(formData);
    for (var i = 0; i < chunks.length; i++) {
      let chunkInfo = chunks[i];
      let uploadResp = await uploadFileChunk(chunkInfo.chunk, chunkInfo.filePartName, existingVideoId, overwriteSections);
      setProgress(((100 * (i + 1)) / chunks.length).toFixed());
      if (uploadResp.status == 201) {
        const { data } = uploadResp;
        videoUploaded = data;
      }
    }//---

    return videoUploaded;
  }

  const saveVideo = async (id) => {
    var _videoToUpload = videoToAdd
    _videoToUpload['sections'] = vidSections

    try {
      const { data: videoUpdated } = await updateVideo(id, _videoToUpload)
      videoUpdated['title'] = videoToAdd.title

      document.querySelector('#upload-video-form').reset()
      setVidSections([])

      addVideo(videoUpdated)
      setShowModal(false)
    } catch ({ response }) {
      console.log(response)
      if (response.data) {
        setErrors(response.data.errors)
        setModalError(response.data.title)
      }
      else
        setModalError('Error Occured: Please Try Again.')
    }
  }

  const handleDeleteVideo = async (videoId, index) => {
    try {
      await deleteVideo(videoId)
      let _videos = videos
      _videos.splice(index, 1)
      setVideos([..._videos])
    } catch (e) {
      console.log(e)
    }
  }

  const changeOverrideVideoSection = () => {
    setEditVideoOverrideVidSections(!editVideoOverrideVidSections)
  }

  return <>
    {/* Edit Video Details Modal */}
    <Modal
      show={videoEditModal}
      onHide={() => setVideoEditModal(false)}
      aria-labelledby="edit-section-modal"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="edit-section-modal">
          Edit Title
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {progress > 0 &&
          <ProgressBar variant="danger" now={progress} label={`${progress}%`} style={{ marginBottom: 15 }} />
        }

        <Form.Group>
          <Form.Group controlId='uv-file'>
            <div><Form.Label>Video File</Form.Label></div>
            <input required id="uv-file-input" type="file" onChange={(event) => setNewVideo(event)} />
            <br></br>
            <input className="mr-1" onChange={() => changeOverrideVideoSection()} type="checkbox" label="Override video sections" checked={editVideoOverrideVidSections} />
            <Form.Label>Override video sections</Form.Label>
          </Form.Group>
          <Form.Control type="text" placeholder="Enter title" defaultValue={oldVideoTitle} onChange={(e) => setNewVideoTitle(e.target.value)} />
        </Form.Group>
        <div className="text-right">
          <Button className="px-4" onClick={() => editVideo()}>Update</Button>
        </div>
      </Modal.Body>
    </Modal>

    {/* Upload Video Modal */}
    <Modal
      size="lg"
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="upload-video-modal"
      centered>
      <Form id="upload-video-form" noValidate>
        <Modal.Header closeButton>
          <Modal.Title id="upload-video-modal">
            Upload Video
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {progress > 0 &&
            <ProgressBar variant="danger" now={progress} label={`${progress}%`} style={{ marginBottom: 15 }} />
          }

          {!errors && modalError &&
            <Alert variant="danger" onClose={() => setModalError('')} dismissible>
              <p className="mb-0">
                {modalError}
              </p>
            </Alert>
          }
          {errors &&
            <Alert variant="danger" onClose={() => setErrors(null)} dismissible>
              <p className="mb-0">
                {
                  Object.keys(errors).map((key) =>
                    <>
                      <span>{errors[key]}</span><br />
                    </>
                  )
                }
              </p>
            </Alert>
          }

          <Form.Group controlId='uv-file'>
            <div><Form.Label>Video File</Form.Label><span className="text-danger">*</span></div>
            <input required id="uv-file-input" type="file" onChange={(event) => setVideo(event)} />
          </Form.Group>
          <Form.Group controlId='uv-title'>
            <Form.Label>Title</Form.Label><span className="text-danger">*</span>
            <Form.Control
              required
              type='text'
              name='title'
              placeholder='Title'
              onChange={(e) => handleVideoToAdd('title', e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="uv-description">
            <Form.Label>Description</Form.Label><span className="text-danger">*</span>
            <Form.Control required
              as="textarea" placeholder='Video Description' rows="3"
              onChange={(e) => handleVideoToAdd('description', e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setShowModal(false)}>Close</Button>
          <Button type="button" onClick={() => handleUpload()} disabled={isUploading}>Save</Button>
        </Modal.Footer>
      </Form>
    </Modal>

    <section>
      <Col>
        <Row className="mb-5">
          <Col>
            <Button onClick={() => setShowModal(true)}>Upload Video</Button>
          </Col>
        </Row>
        {isLoading && <Spinner />}
        {videos.length > 0 &&
          <Row className="mb-4">
            <Col>
              <h5 className="mb-3">All Videos</h5>
              <ol className="pl-3">
                {videos.map((video, index) => (
                  <li key={`video_${index}`} className="remove-icon-parent">
                    <Link className="text-dark" to={`/video/${video.id}`}>{video.title}</Link>
                    <i onClick={() => handleDeleteVideo(video.id, index)} title="delete video" className="remove-icon fas fa-times-circle text-muted cursor-pointer ml-2"></i>
                    <i onClick={() => handleEditVideoTitleModal(video.title, video.id)} title="edit video" className="remove-icon fas fa-pencil-alt text-muted ml-1 cursor-pointer"></i>
                  </li>
                ))}
              </ol>
            </Col>
          </Row>
        }
      </Col>
    </section>
  </>
}

export default VideosAdmin
