import http, { apiUrl } from './http'
import { getToken } from './auth'


export const videoLastViewedSet = (videoId, timeLastViewedInSeconds) => {
  return http.post(apiUrl + 'uservideos/' + videoId + '/' + timeLastViewedInSeconds, {}, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}

export const videoLastViewedGet = (videoId) => {
  return http.get(apiUrl + 'uservideos/' + videoId, {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}


export const getLastViewedVideoTime = () => {
  return http.get(apiUrl + 'uservideos/getlastviewedvideo', {
    headers: {
      'Authorization': 'Bearer ' + getToken()
    }
  })
}