import React, { useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import Spinner from '../../components/spinner'
import { getCurrentUser } from '../../services/auth'
import http from '../../services/http'

const LoginResetPassword = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const [username, setUsername] = useState(queryParams.get('user'))
  const [password, setPassword] = useState()
  const [showPassword, setShowPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState()

  const [loadingError, setLoadingError] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(true)

  // check if user is logged in, redirect back to home page if so
  if (getCurrentUser()) { window.location.assign('/videos') }


  const resetPassword = (e) => {
    e.preventDefault()
    if (isLoading) return

    if (password !== confirmPassword) {
      setLoadingError("Ihre Passwörter stimmen nicht überein.")
      return
    }

    setLoadingError(false)
    setIsLoading(true)

    http.post('login/reset-password', { username, password, token: queryParams.get('token') })
      .then(() => setIsSuccess(true))
      .catch((ex) => setLoadingError(ex.message || "Unbekannter Fehler."))
      .finally(() => setIsLoading(false))
  }


  return <section>
    <Col>
      <Container>
        <Row className='justify-content-center'>
          <Col lg='6'>
            {isSuccess && <Alert variant="success">
              Erfolgreich! Sie können sich jetzt mit Ihrem neuen Passwort <a href="/login">anmelden</a>.
            </Alert>}
            {loadingError && <Alert variant="danger">Es ist ein Fehler aufgetreten! {loadingError}</Alert>}
            <Form onSubmit={resetPassword}>
              <Card className='p-4 mb-4'>
                <h4 className="text-center">Passwort zurücksetzen</h4>
                <p className="mb-5">Geben Sie Ihren Benutzernamen an und wir senden Ihnen eine Email mit dem Link zum Zurücksetzen Ihres Passworts.</p>

                <Form.Group controlId='login-username'>
                  <Form.Label>Benutzername oder Email</Form.Label>
                  <Form.Control disabled
                    type='text' name='username'
                    defaultValue={username}
                    onChange={(event) => setUsername(event.target.value)} />
                </Form.Group>

                <Form.Group controlId='login-password'>
                  <Form.Label>Passwort</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control required disabled={isSuccess}
                      className="border-right-0"
                      type={showPassword ? 'text' : 'password'} name='password'
                      onChange={(event) => setPassword(event.target.value)} />
                    <InputGroup.Append>
                      <InputGroup.Text className="bg-white border-left-0 text-muted">
                        <i className={`far fa-eye${showPassword ? '' : '-slash'} cursor-pointer`}
                          onClick={() => setShowPassword(!showPassword)}></i>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>

                <Form.Group className='mb-5' controlId='login-confirm-password'>
                  <Form.Label>Passwort Bestätigen</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control required disabled={isSuccess}
                      className="border-right-0"
                      type={showPassword ? 'text' : 'password'} name='confirm-password'
                      onChange={(event) => setConfirmPassword(event.target.value)} />
                    <InputGroup.Append>
                      <InputGroup.Text className="bg-white border-left-0 text-muted">
                        <i className={`far fa-eye${showPassword ? '' : '-slash'} cursor-pointer`}
                          onClick={() => setShowPassword(!showPassword)}></i>
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>


                <Button className='mb-3' disabled={isSuccess} type="submit">
                  {isLoading ? <Spinner /> : <>zurücksetzen</>}
                </Button>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Col>
  </section>
}

export default LoginResetPassword
