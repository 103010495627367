import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getCurrentUser } from '../../services/auth';

export default function ProtectedRoute({
  path,
  component: Component,
  render,
  ...rest
}) {

  return (
    <Route
      {...rest}
      render={props => {

        const currentUser = getCurrentUser();
        if (!currentUser) return <Redirect to="/login" />
        return Component ? <Component {...props} /> : render(props)
      }}
    />
  )
}
