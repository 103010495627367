import React from 'react'

export const emailTemplateBody = () => {
  return `<br /><br />
    <table border="1" cellpadding="0" cellspacing="0" width="400px">
      <tr>
        <td style="padding: 4px; width: 100px"><span><b>Link:</b></span></td>
        <td style="padding: 4px"><span><a href="https://onlineportal-zegelman.de">www.onlineportal-zegelman.de</a> </span></td>
      </tr>
    </table>
    <br />
    <span>Hier haben Sie die Möglichkeit, sich Inhalte eigenständig zu erarbeiten oder bereits erlente Inhalte zu vertiefen.</span><br />
    <span>Sollten Ordner oder Teile davon für Sie nicht zugänglich sein, ist eine Auseinandersetzung mit diesen Inhalten (noch) nicht erwünscht.</span><br /><br />
    <span>Ihre benötigten Login-Daten lauten wie folgt:</span>
    <table border="1"  cellpadding="0" cellspacing="0" width="400px">
      <tr><td style="padding: 4px; width: 100px"><b>Benutzername:</b></td><td style="padding: 4px">{username}</td></tr>
      <tr><td style="padding: 4px"><b>Passwort:</b></td><td style="padding: 4px">{password}</td></tr>
      <tr><td style="padding: 4px"><b>Zugangsdauer:</b></td><td style="padding: 4px">{daysvalidity} Tage ab dem ersten Login</td></tr>
    </table><br />
    <span>Sollten Sie weitere Fragen zu meinem Online-Portal oder dessen Nutzung haben, können Sie mich diesbezüglich gerne kontaktieren.</span><br /><br />
    <span>Mit freundlichen Grüßen</span><br />
    <span>F. Zegelman</span><br /><br />
    <hr />`
}

export const signatureEFC = () => {
  return `<span><b>Dipl.-Psych. Felix Zegelman</b></span><br />
    <span style="color: #a51916"><b>CHANGING EMOTIONS</b></span><br />
    <span>Emotionsfokussiertes Coaching</span><br/>
    <br />
    <span>Hauptstraße 25a</span><br />
    <span>65843 Sulzbach (Taunus)</span><br />
    <br />
    <span>Tel.: 06196/80 17 839</span><br />
    <span>Fax: 06196/80 17 835</span><br />
    <span>Email: info@changing-emotions.de</span><br />
    <span>www.changing-emotions.de</span><br />
    <br />
    <img src="https://onlineportal-zegelman.de/resources/logo_efc.jpg" width="90" />
    <br />
    <br />
    <br />
    <span><small>Diese Information ist ausschließlich für den Adressaten bestimmt und kann vertrauliche oder gesetzlich geschützte Informationen enthalten. Wenn Sie nicht der bestimmungsgemäße Adressat sind, unterrichten Sie mich bitte und vernichten Sie diese E-Mail. Anderen als dem bestimmungsgemäßen Adressaten ist es untersagt, diese E-Mail zu lesen, zu speichern, weiterzuleiten oder ihren Inhalt auf welche Weise auch immer zu verwenden. Ich verwende aktuelle Virenschutzprogramme. Für Schäden, die dem Empfänger gleichwohl durch von mir zugesandte mit Viren befallene E-Mails entstehen, schließe ich jede Haftung aus.</small></span>`
}

export const signaturePT = () => {
  return `<span><b>Dipl.-Psych. Felix Zegelman</b></span><br />
    <span>Psychologischer Psychotherapeut</span><br />
    <span>Fachrichtung Verhaltenstherapie</span><br />
    <br />
    <span>Hauptstraße 25a</span><br/>
    <span>65843 Sulzbach (Taunus)</span><br />
    <br />
    <span>Tel.: 06196/80 17 832</span><br />
    <span>Fax: 06196/80 17 835</span><br />
    <span>Email: info@psychotherapie-zegelman.de</span><br />
    <span>www.psychotherapie-zegelman.de</span><br /><br />
    <img src="https://onlineportal-zegelman.de/resources/logo_pt.jpg" width="90" height="100" />
    <br/>
    <br />
    <br />
    <span><small>Diese Information ist ausschließlich für den Adressaten bestimmt und kann vertrauliche oder gesetzlich geschützte Informationen enthalten. Wenn Sie nicht der bestimmungsgemäße Adressat sind, unterrichten Sie mich bitte und vernichten Sie diese E-Mail. Anderen als dem bestimmungsgemäßen Adressaten ist es untersagt, diese E-Mail zu lesen, zu speichern, weiterzuleiten oder ihren Inhalt auf welche Weise auch immer zu verwenden. Ich verwende aktuelle Virenschutzprogramme. Für Schäden, die dem Empfänger gleichwohl durch von mir zugesandte mit Viren befallene E-Mails entstehen, schließe ich jede Haftung aus.</small></span>`
}


const MailTemplatePT = ({ userPassword, username, userDuration, firstParagh, setFirstParagh }) => {
  return <>
    <textarea style={{ height: '70px' }} className='w-100' defaultValue={firstParagh} onChange={(e) => setFirstParagh(e.target.value)} />
    <br /><br />
    <table className='w-100 table-padding'>
      <tbody className='simple-borderline '>
        <tr>
          <td style={{ width: '100px' }}>
            <span><b>Link:</b></span>
          </td>
          <td>
            <span> www.onlineportal-zegelman.de </span>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <span>Hier haben Sie die Möglichkeit, sich Inhalte eigenständig zu erarbeiten oder bereits erlernte Inhalte zu vertiefen.</span><br />
    <span>Sollten Ordner oder Teile davon für Sie nicht zugänglich sein, ist eine Auseinandersetzung mit diesen Inhalten (noch) nicht erwünscht.</span><br /><br />
    <span>Ihre benötigten Login-Daten lauten wie folgt:</span>
    <table className='w-100'>
      <tbody className='simple-borderline table-padding'>
        <tr>
          <td style={{ width: '100px' }}>
            <span><b> Benutzername: </b></span>
          </td>
          <td>
            <span style={{ padding: '0' }}>{username} </span>
          </td>
        </tr>
        <tr >
          <td style={{ width: '100px' }}>
            <span><b>Passwort:</b></span>
          </td>
          <td >
            <span style={{ padding: '0' }}>{userPassword} </span>
          </td>
        </tr>
        <tr >
          <td style={{ width: '100px' }}>
            <span><b> Zugangsdauer: </b></span>
          </td>
          <td>
            <span style={{ padding: '0', width: '30px' }} >{userDuration.replace("days","").replace("day","")} Tage ab dem ersten Login </span>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <span>Sollten Sie weitere Fragen zu meinem Online-Portal oder dessen Nutzung haben, können Sie mich diesbezüglich gerne kontaktieren.</span><br /><br />
    <span>Mit freundlichen Grüßen</span><br />
    <span>F. Zegelman</span><br />
  </>
}

export default MailTemplatePT
